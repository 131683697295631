import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../App";
import { Row, Col } from "react-bootstrap";

import Sidebar from "../Sidebar";
import { getOverview } from "../DMData";
import { DMChart } from "../DMCharts";
import Loader from "../Loader";

const Overview = () => {
  const [data, setData] = useState(null);
  const all = [];
  const postcards = [];
  const nms = [];
  const recall = [];
  const activeClients = [];
  let totalActiveClients = 0;
  let totalMailedThisMonth = 0;

  const context = useContext(UserContext);

  useEffect(() => {
    if (context.accessToken !== null && !data) {
      getOverview(context).then(function (result) {
        setData(result);
      });
    }
  }); // Initial page load

  if (context.accessToken === null) return <></>;

  if (data) {
    const today = new Date();
    for (const d in data.All) {
      all.push({ name: d, line1: data.All[d] });
      if (d === today.getUTCFullYear() + "-" + (today.getUTCMonth() + 1)) {
        totalMailedThisMonth += +data.All[d];
      }
    }
    for (const d in data.PC) {
      postcards.push({ name: d, line1: data.PC[d] });
    }
    for (const d in data.NM) {
      nms.push({ name: d, line1: data.NM[d] });
    }
    for (const d in data.RC) {
      recall.push({ name: d, line1: data.RC[d] });
    }
    for (const d in data.ClientsPerActiveStatus) {
      activeClients.push({ name: d, line1: +data.ClientsPerActiveStatus[d] });
      totalActiveClients += +data.ClientsPerActiveStatus[d];
    }
  }

  return (
    <>
      <Sidebar />
      <div className="content">
        {!data && <Loader />}
        {data && (
          <>
            <Row>
              <Col>
                <h2>Highest Mailing Client</h2>
                <h1>{data.ClientNameMostMailed}</h1>
                <h1 className="blue">
                  {data.ClientQuantityMostMailed.toLocaleString("en-US")}
                </h1>
                <br />
                <h2>Total Number of Active Clients</h2>
                <h1 className="blue">
                  {totalActiveClients.toLocaleString("en-US")}
                </h1>
                <br />
                <h2>Postcards Mailed This Month</h2>
                <h1 className="blue">
                  {totalMailedThisMonth.toLocaleString("en-US")}
                </h1>
              </Col>
              <Col>
                <DMChart
                  title="Total Active Clients"
                  subtitle="Per Status"
                  label="Status"
                  data={activeClients}
                  type="bar"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <DMChart
                  title="Total Quantity Hit-Home"
                  subtitle="All Order Types - Last 12 Months"
                  label="Total Quantity"
                  data={all.reverse()}
                />
              </Col>
              <Col>
                <DMChart
                  title="PC Quantity Hit-Home"
                  subtitle="Postcard Mailing - Last 12 Months"
                  label="Total Quantity"
                  data={postcards.reverse()}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <DMChart
                  title="NM Quantity Hit-Home"
                  subtitle="New Move-In - Last 12 Months"
                  label="Total Quantity"
                  data={nms.reverse()}
                />
              </Col>
              <Col>
                <DMChart
                  title="RC Quantity Hit-Home"
                  subtitle="Internal Marketing Cards - Last 12 Months"
                  label="Total Quantity"
                  data={recall.reverse()}
                />
              </Col>
            </Row>
          </>
        )}
      </div>
    </>
  );
};

export default Overview;
