import {
  greenColor,
  multicolor,
  orangeColor,
  redColor,
  StackedGreenRedSetup,
  StackedGreenYellowRedGraySetup,
  StackedGreenYellowRedSetup,
  StackedMulticolorSetup,
  yellowColor,
} from "../DMCharts";

import _ from "lodash";
import { getDate } from "../Utils";

/*
Poor Results
Good Results - Client Doesn't Agree
Capacity - Too Many Patients
Budget
Budget Allocated to Online Marketing
Other
Staffing Issues
Fired Client - not a good fit
Not Happy with Us
*/
export const _inactiveDrip = () => {
  return {
    labels: ["Status"],
    datasets: StackedMulticolorSetup([
      "Poor Results",
      "Good Results - Client Doesn't Agree",
      "Capacity - Too Many Patients",
      "Budget",
      "Budget Allocated to Online Marketing",
      "Other",
      "Staffing Issues",
      "Fired Client - not a good fit",
      "Not Happy with Us",
    ]),
  };
};
export const _cardsPayingThisMonth = () => {
  return {
    labels: ["Team Goals"],
    datasets: StackedGreenYellowRedSetup([
      "Below 85%",
      "85% or above",
      "100% or above",
    ]),
  };
};
export const _paidLastMonth = () => {
  return {
    labels: ["Paid Last Month"],
    datasets: StackedGreenYellowRedSetup([
      "-",
      "Not On Plan",
      "According to Plan",
    ]),
  };
};

export const _bookHygieneNoOrders = () => {
  return {
    labels: ["Order Review"],
    datasets: StackedGreenYellowRedSetup([
      "No Orders",
      "Less than expected",
      "As expected",
    ]),
  };
};
export const _bookHygieneNoPayment = () => {
  return {
    labels: ["Payment Status"],
    datasets: StackedGreenYellowRedSetup([
      "Overdue",
      "No Payment Date",
      "Paid On Time",
    ]),
  };
};
export const _bookHygieneInPlan = () => {
  return {
    labels: ["On Plan Status"],
    datasets: StackedGreenYellowRedSetup([
      "2 Months Due",
      "1 Month Due",
      "On Plan",
    ]),
  };
};
export const _bookHygieneNoActivities = () => {
  return {
    labels: ["Active Clients"],
    datasets: StackedGreenRedSetup(["No Open Activity", "Activities Planned"]),
  };
};
export const _proofreading = () => {
  return {
    labels: [
      "Print Week is Current Week",
      "Print Week is 1 Week Away",
      "Print Week is 2 or more weeks away",
    ],
    datasets: [
      {
        label: "Proofreading",
        data: [],
        backgroundColor: [redColor, yellowColor, greenColor],
      },
    ],
  };
};
export const _firstCalls = () => {
  return {
    labels: ["First Call Tasks"],
    datasets: StackedGreenYellowRedGraySetup([
      "Missing Task",
      "Open",
      "Completed",
      "-",
    ]),
  };
};
export const _fulfillment = () => {
  return {
    labels: ["Fulfillment Status"],
    datasets: StackedGreenYellowRedGraySetup([
      "Shipped / Sent to Printer",
      "En Route To Homes",
      "Currently Receiving Calls",
    ]),
  };
};
export const _noStart = () => {
  return {
    labels: ["No Starts"],
    datasets: StackedGreenYellowRedGraySetup([
      "Neighborhood Route",
      "New Move-In",
      "-",
    ]),
  };
};
export const _shippingNotes = () => {
  return {
    labels: ["Shipping Notes"],
    datasets: StackedGreenYellowRedGraySetup([
      "Delayed Shipping",
      "Shipping Notes",
      "-",
    ]),
  };
};
export const _progressReport = () => {
  return {
    labels: ["Progress Report Sent"],
    datasets: StackedGreenYellowRedGraySetup([
      "Not Yet Sent",
      "Not Yet Sent",
      "Sent",
    ]),
  };
};
export const _saiDue = () => {
  return {
    labels: ["Last 30 Days"],
    datasets: StackedGreenYellowRedGraySetup([
      "No Date | Completed Late",
      "2 Day Overdue",
      "Completed Within 2 Days",
    ]),
  };
};
export const _rrTasks = () => {
  return {
    labels: ["Last 30 Days"],
    datasets: StackedGreenYellowRedGraySetup([
      "Overdue",
      "Open Due Today",
      "Completed / Within time",
      "No RR",
    ]),
  };
};
export const _designStages = () => {
  return {
    labels: [],
    datasets: StackedGreenYellowRedSetup([
      "No Stage",
      "Not Aproved Yet",
      "Approved",
    ]),
  };
};
export const _mapStages = () => {
  return {
    labels: [],
    datasets: StackedGreenYellowRedSetup([
      "No Stage",
      "Not Aproved Yet",
      "Approved",
    ]),
  };
};
export const _RRMonthTasks = () => {
  return {
    labels: [],
    datasets: StackedGreenYellowRedSetup(["Overdue", "Planned", "Completed"]),
  };
};
export const _ScheduledRRMonthTasks = () => {
  return {
    labels: [],
    datasets: StackedGreenYellowRedSetup(["Overdue", "Planned", "Completed"]),
  };
};
export const _BOB = () => {
  return {
    labels: ["Status"],
    datasets: StackedMulticolorSetup([
      "DM - NEW",
      "Active - Initial",
      "Active - Renewed",
      "Active - Additional",
      "Active - Corporate",
      "Active - Determine Relationship",
    ]),
  };
};
export const _BOBbyStatus = () => {
  return {
    labels: [
      "DM - NEW",
      "Active - Initial",
      "Active - Renewed",
      "Active - Additional",
      "Active - Corporate",
      "Active - Determine Relationship",
    ],
    datasets: [
      {
        label: "0",
        data: [],
        backgroundColor: [
          multicolor[0],
          multicolor[1],
          multicolor[2],
          multicolor[3],
          multicolor[1],
          orangeColor,
        ],
        hoverOffset: 4,
      },
    ],
  };
};
export const _BOBbyPlan = () => {
  return {
    labels: ["On Plan", "Deviating", "One-Off", "No Plan"],
    datasets: [
      {
        label: "0",
        data: [],
        backgroundColor: [multicolor[0], orangeColor, yellowColor, redColor],
        hoverOffset: 4,
      },
    ],
  };
};

export const _inactiveAdditional = () => {
  return {
    labels: ["Inactive Additional"],
    datasets: StackedGreenYellowRedGraySetup([
      "Inactive Additional",
      "Inactive Additional",
      "Inactive Additional",
    ]),
  };
};

export const _lastMinute = () => {
  return {
    labels: ["Last Minute"],
    datasets: [
      {
        label: "0",
        data: [],
        backgroundColor: [redColor],
        hoverOffset: 4,
      },
    ],
  };
};

export const _upcomingRR = () => {
  return {
    labels: ["Upcoming RR"],
    datasets: StackedGreenYellowRedGraySetup([
      "1 Day Left",
      "2-5 Days Left",
      "Completed",
    ]),
  };
};

export const _tasksColumns = () => {
  return [
    {
      name: "Subject",
      selector: (row) => row.Subject,
      sortable: true,
      wrap: true,
      sortFunction: (rowA, rowB) => {
        const a = rowA.Subject.toLowerCase();
        const b = rowB.Subject.toLowerCase();

        return a > b ? 1 : b > a ? -1 : 0;
      },
      style: {
        textAlign: "left",
      },
    },
    {
      name: "Date",
      selector: (row) => row.ActivityDate,
      sortable: true,
      width: "100px",
      style: {
        backgroundColor: "#f7f7f7",
      },
    },
    {
      name: "Account",
      selector: (row) => row.What.Name,
      sortFunction: (rowA, rowB) => {
        const a = rowA.What.Name.toLowerCase();
        const b = rowB.What.Name.toLowerCase();

        return a > b ? 1 : b > a ? -1 : 0;
      },
      sortable: true,
    },
  ];
};

export const _groupTasks = (tasks, tasksArray, today) => {
  _.forIn(
    _.groupBy(
      tasks.filter((item) => {
        return today
          ? getDate(item.ActivityDate).toDateString() ===
              getDate().toDateString()
          : getDate(item.ActivityDate) < getDate();
      }),
      "Owner.Name"
    ),
    (data) => {
      tasksArray.push(data);
    }
  );
};
