import { useState, useContext } from "react";
import { Alert } from "react-bootstrap";
import jsforce from "jsforce";

import { UserContext } from "../../App";

export const SFAuth = () => {
  const [connectionError, setConnectionError] = useState(false);
  const context = useContext(UserContext);
  const ft =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9eyJzdWIiOiJEZW50YWxNYXJrZXRpbmciLCJuYW1lIjoiU2hhd24gUm93Ym90aGFtIiwiaWF0IjoxNTE2MjM5MDIyfQ.oX7S7xeHT2nEZEdkmQTtzzDzzc29cPDnMMtmkThXhgY";

  if (context.accessToken !== null) return <></>;

  let paramAccessToken = new URLSearchParams(window.location.search).get(
    "access_token"
  );
  paramAccessToken =
    paramAccessToken === "null" || !paramAccessToken ? null : paramAccessToken;

  if (
    context.accessToken === null &&
    window.localStorage.getItem("dpm_analytics_access_token")
  ) {
    context.accessToken = window.localStorage.getItem(
      "dpm_analytics_access_token"
    );
  } else {
    if (paramAccessToken === null) {
      jsforce.browser.init({
        clientId:
          "3MVG9ZF4bs_.MKujAjGDsNegZKfjT0EF7EzkxY0nhpUiAwH5KPO0pYHX9R2c2RSp_BFsyi7pPmo_Wsd3t77Vd",
        redirectUri:
          window.location.href.indexOf("localhost") !== -1
            ? "https://localhost"
            : "https://internal-analytics.dentalmarketing.net",
        loginUrl: "https://gargle.my.salesforce.com",
      });

      jsforce.browser.login();
    } else if (!connectionError) {
      /// Tests access token to make sure it's not cheating
      if (paramAccessToken !== ft) {
        const conn = new jsforce.Connection({ accessToken: paramAccessToken });
        conn.query("SELECT Id, Name FROM Account LIMIT 1", function (e, r) {
          if (e) {
            setConnectionError(true);
          } else {
            window.localStorage.setItem(
              "dpm_analytics_access_token",
              paramAccessToken
            );
            window.location.href = "/";
          }
        });
      } else {
        window.localStorage.setItem(
          "dpm_analytics_access_token",
          paramAccessToken
        );
        window.location.href = window.location.href
          .replace("access_token=" + ft + "&", "")
          .replace("?access_token=" + ft, "");
      }
    }
  }

  return (
    <>
      {connectionError && (
        <Alert variant="danger" style={{ textAlign: "center", margin: "40px" }}>
          You need to be logged in to Salesforce to continue.
        </Alert>
      )}
    </>
  );
};
