import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../App";

import Sidebar from "../Sidebar";
import { getVP } from "../DMData";
import {
  blueColor,
  DMPieChart,
  DMStackedChart,
  greenColor,
  orangeColor,
  redColor,
  yellowColor,
} from "../DMCharts";
import Loader from "../Loader";
import ErrorMessage from "../ErrorMessage";

import {
  _BOBbyPlan,
  _BOBbyStatus,
  _bookHygieneNoOrders,
  _bookHygieneNoPayment,
  _groupTasks,
  _inactiveAdditional,
  _lastMinute,
  _noStart,
  _upcomingRR,
  _cardsPayingThisMonth,
  _paidLastMonth,
  _inactiveDrip,
  _RRMonthTasks,
  _ScheduledRRMonthTasks,
} from "../CSInit";

import { getStorageValue, openURLInNewTab } from "../Utils";
import Filter from "../Filter";
import OpenTasksTable from "../OpenTasksTable";

const VP = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(false);

  const openTasks = [];
  const overdueTasks = [];
  const userOptionsVPs = [];

  const bookHygieneNoOrdersPC = _bookHygieneNoOrders();
  const bookHygieneNoOrdersNM = _bookHygieneNoOrders();
  const bookHygieneNoPaymentPC = _bookHygieneNoPayment();
  const bookHygieneNoPaymentNM = _bookHygieneNoPayment();
  const noStart = _noStart();
  const inactiveAdditional = _inactiveAdditional();
  const lastMinute = _lastMinute();
  const upcomingRR = _upcomingRR();
  const cardsPayingThisMonth = _cardsPayingThisMonth();
  const paidLastMonthPC = _paidLastMonth();
  const paidLastMonthNM = _paidLastMonth();
  const inactiveDrip = _inactiveDrip();
  const rr = _RRMonthTasks();
  const srr = _ScheduledRRMonthTasks();

  const bob = _BOBbyStatus();
  const bobbyplan = _BOBbyPlan();

  const darkFont = true;

  const context = useContext(UserContext);

  const refresh = (load) => {
    if (context.accessToken !== null && load && !context.isLoading) {
      context.isLoading = true;
      getVP(context).then(function (result) {
        refreshData(result);
      });
    }
  };

  const refreshData = (result) => {
    if (result === null) setError(true);
    else {
      setData(result);
      setError(false);
    }
  };

  useEffect(() => {
    refresh(!data);
  }); // Initial page load

  if (context.accessToken === null) return <></>;

  if (getStorageValue("dpm_analytics_vp_id"))
    context.VPId = getStorageValue("dpm_analytics_vp_id");

  if (data) {
    /*
    Poor Results
    Good Results - Client Doesn't Agree
    Capacity - Too Many Patients
    Budget
    Budget Allocated to Online Marketing
    Other
    Staffing Issues
    Fired Client - not a good fit
    Not Happy with Us
    */
    let totalDrip = 0;
    for (const status in data.InactiveDrip) {
      const ds = inactiveDrip.datasets.find((item) => item.label === status);
      if (ds !== undefined) {
        ds.data.push(data.InactiveDrip[status]);
        totalDrip += data.InactiveDrip[status];
      }
    }
    inactiveDrip.labels[0] = totalDrip;

    paidLastMonthPC.datasets[1].data.push(data.PaidLastMonthNotThisPC.Yellow);
    paidLastMonthPC.datasets[0].data.push(data.PaidLastMonthNotThisPC.Green);
    paidLastMonthPC.labels[0] =
      data.PaidLastMonthNotThisPC.Yellow + data.PaidLastMonthNotThisPC.Green;

    paidLastMonthNM.datasets[1].data.push(data.PaidLastMonthNotThisNM.Yellow);
    paidLastMonthNM.datasets[0].data.push(data.PaidLastMonthNotThisNM.Green);
    paidLastMonthNM.labels[0] =
      data.PaidLastMonthNotThisNM.Yellow + data.PaidLastMonthNotThisNM.Green;

    cardsPayingThisMonth.datasets[2].data.push(data.CardsPayingThisMonth.Red);
    cardsPayingThisMonth.datasets[1].data.push(
      data.CardsPayingThisMonth.Yellow
    );
    cardsPayingThisMonth.datasets[0].data.push(data.CardsPayingThisMonth.Green);
    cardsPayingThisMonth.labels[0] = new Intl.NumberFormat().format(
      data.CardsPayingThisMonth.Red +
        data.CardsPayingThisMonth.Yellow +
        data.CardsPayingThisMonth.Green
    );

    bookHygieneNoOrdersPC.datasets[2].data.push(data.NoOrdersPC.Red);
    bookHygieneNoOrdersPC.datasets[1].data.push(data.NoOrdersPC.Yellow);
    bookHygieneNoOrdersPC.datasets[0].data.push(data.NoOrdersPC.Green);
    bookHygieneNoOrdersPC.labels[0] =
      data.NoOrdersPC.Red + data.NoOrdersPC.Yellow + data.NoOrdersPC.Green;

    bookHygieneNoOrdersNM.datasets[2].data.push(data.NoOrdersNM.Red);
    bookHygieneNoOrdersNM.datasets[1].data.push(data.NoOrdersNM.Yellow);
    bookHygieneNoOrdersNM.datasets[0].data.push(data.NoOrdersNM.Green);
    bookHygieneNoOrdersNM.labels[0] =
      data.NoOrdersNM.Red + data.NoOrdersNM.Yellow + data.NoOrdersNM.Green;

    bookHygieneNoPaymentPC.datasets[2].data.push(data.NoPaymentPC.Red);
    bookHygieneNoPaymentPC.datasets[1].data.push(data.NoPaymentPC.Yellow);
    bookHygieneNoPaymentPC.datasets[0].data.push(data.NoPaymentPC.Green);
    bookHygieneNoPaymentPC.labels[0] =
      data.NoPaymentPC.Red + data.NoPaymentPC.Yellow + data.NoPaymentPC.Green;

    bookHygieneNoPaymentNM.datasets[2].data.push(data.NoPaymentNM.Red);
    bookHygieneNoPaymentNM.datasets[1].data.push(data.NoPaymentNM.Yellow);
    bookHygieneNoPaymentNM.datasets[0].data.push(data.NoPaymentNM.Green);
    bookHygieneNoPaymentNM.labels[0] =
      data.NoPaymentNM.Red + data.NoPaymentNM.Yellow + data.NoPaymentNM.Green;

    noStart.datasets[2].data.push(data.NoStart.Red);
    noStart.datasets[1].data.push(data.NoStart.Yellow);
    noStart.datasets[0].data.push(data.NoStart.Green);
    noStart.labels[0] =
      data.NoStart.Red + data.NoStart.Yellow + data.NoStart.Green;

    inactiveAdditional.datasets[1].data.push(data.InactiveAdditional.Green);
    inactiveAdditional.datasets[1].backgroundColor = blueColor;
    inactiveAdditional.datasets[1].borderColor = blueColor;
    inactiveAdditional.labels[0] = data.InactiveAdditional.Green;

    lastMinute.datasets[0].data.push(data.LastMinute.Red);
    lastMinute.labels[0] = data.LastMinute.Red;

    upcomingRR.datasets[2].data.push(data.UpcomingRR.Red);
    upcomingRR.datasets[1].data.push(data.UpcomingRR.Yellow);
    upcomingRR.datasets[0].data.push(data.UpcomingRR.Green);
    upcomingRR.labels[0] =
      data.UpcomingRR.Red + data.UpcomingRR.Yellow + data.UpcomingRR.Green;

    const rrLabels = [
      ...new Set([
        ...Object.keys(data.RRRed),
        ...Object.keys(data.RRYellow),
        ...Object.keys(data.RRGreen),
        ...Object.keys(data.SRRRed),
        ...Object.keys(data.SRRYellow),
        ...Object.keys(data.SRRGreen),
      ]),
    ].sort((a, b) => new Date(a) - new Date(b));
    rr.labels = rrLabels;
    srr.labels = rrLabels;

    for (const k of rrLabels) {
      rr.datasets[2].data.push(data.RRRed[k]);
      rr.datasets[1].data.push(data.RRYellow[k]);
      rr.datasets[0].data.push(data.RRGreen[k]);
    }
    for (const k of rrLabels) {
      srr.datasets[2].data.push(data.SRRRed[k]);
      srr.datasets[1].data.push(data.SRRYellow[k]);
      srr.datasets[0].data.push(data.SRRGreen[k]);
    }

    /*
      "DM - NEW",
      "Active - Initial",
      "Active - Renewed",
      "Active - Additional",
      "Active - Corporate",
      "Active - Determine Relationship",
    */
    let totalBOB = 0;
    bob.datasets[0].data.push(data.BOB["DM - NEW"]);
    bob.datasets[0].data.push(data.BOB["Active - Initial"]);
    bob.datasets[0].data.push(data.BOB["Active - Renewed"]);
    bob.datasets[0].data.push(data.BOB["Active - Additional"]);
    bob.datasets[0].data.push(data.BOB["Active - Corporate"]);
    bob.datasets[0].data.push(data.BOB["Active - Determine Relationship"]);
    for (const status in data.BOB) {
      totalBOB += data.BOB[status];
    }
    bob.datasets[0].label = totalBOB;

    /*
      "On Plan",
      "Deviating",
      "One-Off",
      "No Plan",
    */
    let totalBOBbyPlan = 0;
    bobbyplan.datasets[0].data.push(data.BOBbyPlan["On Plan"]);
    bobbyplan.datasets[0].data.push(data.BOBbyPlan["Deviating"]);
    bobbyplan.datasets[0].data.push(data.BOBbyPlan["One-Off"]);
    bobbyplan.datasets[0].data.push(data.BOBbyPlan["No Plan"]);
    for (const plan in data.BOBbyPlan) {
      totalBOBbyPlan += data.BOBbyPlan[plan];
    }
    bobbyplan.datasets[0].label = totalBOBbyPlan;

    _groupTasks(data.OpenTasks, openTasks, true);
    _groupTasks(data.OpenTasks, overdueTasks, false);

    userOptionsVPs.push({ value: "null", label: "All Users" });

    for (const u of data.VPs) {
      userOptionsVPs.push({ value: u.Id, label: u.Name });
    }

    if (
      context.VPId &&
      userOptionsVPs.find((item) => item.value === context.VPId) !== undefined
    ) {
      context.VP = userOptionsVPs.find((item) => item.value === context.VPId);
    } else {
      context.VPId = null;
    }
  }

  return (
    <>
      {error && <ErrorMessage />}
      {!error && (
        <>
          <Sidebar />
          <div className="content dashboard">
            {!data && <Loader />}
            {data && (
              <>
                <Filter
                  context={context}
                  userOptionsVPs={userOptionsVPs}
                  filter={(e, setShowFilter) => {
                    e.target.disabled = true;
                    e.target.innerHTML = "Loading...";
                    window.location.reload();
                  }}
                />
                <div
                  className="chart-container chart-col-100"
                  style={{
                    gridTemplateColumns: "15% 14% 14% 14% 14% 22%",
                  }}
                >
                  <div className="cs-title">
                    {!context.VPId || context.VP.value === "null"
                      ? "All Users"
                      : context.VP.label}
                    <p className="cs-legend">
                      <span
                        className="square"
                        style={{ background: greenColor }}
                      ></span>
                      <span>Completed / On Time</span>
                      <span
                        className="square"
                        style={{ background: yellowColor }}
                      ></span>
                      <span>Needs Attention</span>
                      <span
                        className="square"
                        style={{ background: redColor }}
                      ></span>
                      <span>Urgent / Overdue</span>
                      <span
                        className="square"
                        style={{ background: orangeColor }}
                      ></span>
                      <span>Out of My Control</span>
                    </p>
                  </div>
                  <div
                    className="chart-col"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      openURLInNewTab(
                        "https://gargle.lightning.force.com/lightning/r/Report/00O2E000009TcIqUAK/view"
                      );
                    }}
                  >
                    <DMStackedChart
                      subtitle="Cards Paying This Month"
                      data={cardsPayingThisMonth}
                      darkFont={darkFont}
                    />
                  </div>
                  <div
                    className="chart-col"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      window.location.href =
                        "/report?action=getNOORDERSPC&report=NOORDERS&fields=Name_Quantity&fieldnames=Account Name_QTY Next Orders NM&returnto=/vp";
                    }}
                  >
                    <DMStackedChart
                      subtitle="Accounts W/O Next PC"
                      data={bookHygieneNoOrdersPC}
                      toolTip={`Any Account who may need more orders created, based on their plan type.`}
                    />
                  </div>
                  <div
                    className="chart-col"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      window.location.href =
                        "/report?action=getNOORDERSNM&report=NOORDERS&fields=Name_Quantity&fieldnames=Account Name_QTY Next Orders NM&returnto=/vp";
                    }}
                  >
                    <DMStackedChart
                      subtitle="Accounts W/O Next NM"
                      data={bookHygieneNoOrdersNM}
                      toolTip={`Any Account who may need more orders created, based on their plan type.`}
                    />
                  </div>
                  <div
                    className="chart-col"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      openURLInNewTab(
                        "https://gargle.lightning.force.com/lightning/r/Report/00O2T000007AsPHUA0/view?queryScope=userFolders"
                      );
                    }}
                  >
                    <DMStackedChart
                      subtitle="No Payment (PC)"
                      data={bookHygieneNoPaymentPC}
                      darkFont={darkFont}
                    />
                  </div>
                  <div
                    className="chart-col"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      openURLInNewTab(
                        "https://gargle.lightning.force.com/lightning/r/Report/00O2T000007AsPMUA0/view?queryScope=userFolders"
                      );
                    }}
                  >
                    <DMStackedChart
                      subtitle="No Payment (NM)"
                      data={bookHygieneNoPaymentNM}
                      darkFont={darkFont}
                    />
                  </div>

                  <div
                    className="chart-col"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      openURLInNewTab(
                        "https://gargle.lightning.force.com/lightning/r/Report/00O2T000004aJPSUA2/view"
                      );
                    }}
                  >
                    <DMPieChart
                      subtitle="BOB Report by Status"
                      data={bob}
                      darkFont={darkFont}
                      toolTip={`Shows all active accounts by account status.`}
                      isDoughnut={true}
                      total={bob.datasets[0].label}
                    />
                  </div>
                </div>

                <div
                  className="chart-container chart-col-100"
                  style={{
                    gridTemplateColumns: "12% 12% 12% 12% 12% 12% 22%",
                  }}
                >
                  <div
                    className="chart-col"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      openURLInNewTab(
                        "https://gargle.lightning.force.com/lightning/r/Report/00O2T000007J6nXUAS/view"
                      );
                    }}
                  >
                    <DMStackedChart
                      subtitle="Inactive Additional"
                      data={inactiveAdditional}
                      darkFont={darkFont}
                    />
                  </div>
                  <div
                    className="chart-col"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      openURLInNewTab(
                        "https://gargle.lightning.force.com/lightning/r/Report/00O2T000007J4odUAC/view"
                      );
                    }}
                  >
                    <DMStackedChart
                      subtitle="Inactive Drip This Month"
                      data={inactiveDrip}
                      darkFont={darkFont}
                    />
                  </div>
                  <div
                    className="chart-col"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      window.location.href =
                        "/report?action=getPAIDLASTMONTHPC&report=PAIDLASTMONTH&fields=LastMonthPC_ThisMonthPC_PlanType&fieldnames=Last Month NR_This Month NR_Plan Type&returnto=/vp";
                    }}
                  >
                    <DMStackedChart
                      subtitle="Paid Last Month, Not This (PC)"
                      data={paidLastMonthPC}
                      darkFont={darkFont}
                    />
                  </div>
                  <div
                    className="chart-col"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      window.location.href =
                        "/report?action=getPAIDLASTMONTHNM&report=PAIDLASTMONTH&fields=LastMonthNM_ThisMonthNM_PlanType&fieldnames=Last Month NM_This Month NM_Plan Type&returnto=/vp";
                    }}
                  >
                    <DMStackedChart
                      subtitle="Paid Last Month, Not This (NM)"
                      data={paidLastMonthNM}
                      darkFont={darkFont}
                    />
                  </div>
                  <div
                    className="chart-col"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      openURLInNewTab(
                        "https://gargle.lightning.force.com/lightning/r/Report/00O2T000006WqGFUA0/view"
                      );
                    }}
                  >
                    <DMStackedChart
                      subtitle="No Starts"
                      data={noStart}
                      darkFont={darkFont}
                    />
                  </div>
                  <div
                    className="chart-col"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      openURLInNewTab(
                        "https://gargle.lightning.force.com/lightning/r/Report/00O2T000006WqG5UAK/view"
                      );
                    }}
                  >
                    <DMStackedChart
                      subtitle="Last Minute Approvals"
                      data={lastMinute}
                      darkFont={darkFont}
                    />
                  </div>
                  <div
                    className="chart-col"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      openURLInNewTab(
                        "https://gargle.lightning.force.com/lightning/r/Report/00O2T000006WqExUAK/view"
                      );
                    }}
                  >
                    <DMPieChart
                      subtitle="BOB Report by Plan"
                      data={bobbyplan}
                      darkFont={darkFont}
                      toolTip={`Shows all active accounts by account plan.`}
                      isDoughnut={true}
                      total={bob.datasets[0].label}
                    />
                  </div>
                </div>
                <div
                  className="chart-container chart-col-100"
                  style={{
                    gridTemplateColumns: "10% 42% 42%",
                  }}
                >
                  <div className="stats">
                    <div
                      style={{
                        marginTop: "50%",
                      }}
                    >
                      <a
                        href="https://gargle.lightning.force.com/lightning/r/Report/00O2T000007J5EWUA0/view"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        <h4>Lead Referrals</h4>
                        <br />
                        <h5>{data.Referrals.Created}</h5>
                      </a>
                    </div>
                    <div>
                      <a
                        href="https://gargle.lightning.force.com/lightning/r/Report/00O2T000007J5EWUA0/view"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        <h4>Leads Closed</h4>
                        <br />
                        <h5>{data.Referrals.Converted}</h5>
                      </a>
                    </div>
                    <div>
                      <a
                        href="https://gargle.lightning.force.com/lightning/r/Report/00O2T000007J6VnUAK/view"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        <h4>Bonus Paid</h4>
                        <br />
                        <h5>{data.Referrals.Paid}</h5>
                      </a>
                    </div>
                  </div>
                  <div
                    className="chart-col chart-col-33"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      openURLInNewTab(
                        "https://gargle.lightning.force.com/lightning/r/Report/00O2T000007J5EwUAK/view"
                      );
                    }}
                  >
                    <DMStackedChart
                      subtitle="RR This Month"
                      data={rr}
                      darkFont={darkFont}
                      toolTip={`RR Calls This Month`}
                    />
                  </div>
                  <div
                    className="chart-col chart-col-33"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      openURLInNewTab(
                        "https://gargle.lightning.force.com/lightning/r/Report/00O2T000007J6dIUAS/view"
                      );
                    }}
                  >
                    <DMStackedChart
                      subtitle="Schedule RR This Month"
                      data={srr}
                      darkFont={darkFont}
                      toolTip={`Schedule RR Calls This Month`}
                    />
                  </div>
                </div>

                <OpenTasksTable
                  openTasks={openTasks}
                  overdueTasks={overdueTasks}
                />
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default VP;
