import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../App";

import Sidebar from "../Sidebar";
import { getCSC } from "../DMData";
import {
  DMPieChart,
  DMStackedChart,
  greenColor,
  orangeColor,
  redColor,
  yellowBorderColor,
  yellowColor,
} from "../DMCharts";
import Loader from "../Loader";

import { getStorageValue, openURLInNewTab } from "../Utils";
import Filter from "../Filter";
import {
  _BOB,
  _bookHygieneInPlan,
  _bookHygieneNoActivities,
  _bookHygieneNoOrders,
  _designStages,
  _firstCalls,
  _fulfillment,
  _groupTasks,
  _mapStages,
  _proofreading,
  _rrTasks,
  _saiDue,
} from "../CSInit";
import OpenTasksTable from "../OpenTasksTable";
import ErrorMessage from "../ErrorMessage";

const CSC = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(false);

  const openTasks = [];
  const overdueTasks = [];
  const userOptionsCSC = [];

  const bookHygieneNoOrders = _bookHygieneNoOrders();
  const bookHygieneInPlan = _bookHygieneInPlan();
  const bookHygieneNoActivities = _bookHygieneNoActivities();
  const proofreading = _proofreading();
  const firstCalls = _firstCalls();
  const fulfillment = _fulfillment();
  const saiDue = _saiDue();
  const rrTasks = _rrTasks();
  const designStages = _designStages();
  const mapStages = _mapStages();
  const bob = _BOB();

  const darkFont = true;

  const context = useContext(UserContext);

  const refresh = (load) => {
    if (context.accessToken !== null && load && !context.isLoading) {
      context.isLoading = true;
      getCSC(context).then(function (result) {
        refreshData(result);
      });
    }
  };

  const refreshData = (result) => {
    if (result === null) setError(true);
    else {
      setData(result);
      setError(false);
    }
  };

  useEffect(() => {
    refresh(!data);
  }); // Initial page load

  if (context.accessToken === null) return <></>;

  if (getStorageValue("dpm_analytics_csc_id"))
    context.CSCId = getStorageValue("dpm_analytics_csc_id");

  if (data) {
    console.log(data);
    bookHygieneNoOrders.datasets[2].data.push(data.NoOrders.Red);
    bookHygieneNoOrders.datasets[1].data.push(data.NoOrders.Yellow);
    bookHygieneNoOrders.datasets[0].data.push(data.NoOrders.Green);
    bookHygieneNoOrders.labels[0] =
      data.NoOrders.Red + data.NoOrders.Yellow + data.NoOrders.Green;

    bookHygieneInPlan.datasets[2].data.push(data.NotInPlan.Red);
    bookHygieneInPlan.datasets[1].data.push(0);
    bookHygieneInPlan.datasets[0].data.push(
      data.NotInPlan.Green + data.NotInPlan.Yellow
    );
    bookHygieneInPlan.labels[0] =
      data.NotInPlan.Red + data.NotInPlan.Yellow + data.NotInPlan.Green;

    bookHygieneNoActivities.datasets[1].data.push(data.NoActivities.Red);
    bookHygieneNoActivities.datasets[0].data.push(data.NoActivities.Green);
    bookHygieneNoActivities.labels[0] =
      data.NoActivities.Red + data.NoActivities.Green;

    proofreading.datasets[0].data.push(data.Proofreading.Red);
    proofreading.datasets[0].data.push(data.Proofreading.Yellow);
    proofreading.datasets[0].data.push(data.Proofreading.Green);
    proofreading.labels[0] =
      data.Proofreading.Red +
      data.Proofreading.Yellow +
      data.Proofreading.Green;

    saiDue.datasets[2].data.push(data.SAIDue.Red);
    saiDue.datasets[1].data.push(data.SAIDue.Yellow);
    saiDue.datasets[0].data.push(data.SAIDue.Green);
    saiDue.labels[0] = data.SAIDue.Red + data.SAIDue.Yellow + data.SAIDue.Green;

    console.log(rrTasks);
    rrTasks.datasets[2].data.push(data.RR.Red);
    rrTasks.datasets[1].data.push(data.RR.Yellow);
    rrTasks.datasets[0].data.push(data.RR.Green);
    rrTasks.datasets[3].data.push(data.RR.Gray);
    rrTasks.labels[0] =
      data.RR.Red + data.RR.Yellow + data.RR.Green + data.RR.Gray;
    rrTasks.datasets[3].backgroundColor = orangeColor;
    rrTasks.datasets[3].borderColor = orangeColor;

    firstCalls.datasets[1].data.push(data.FirstCalls.Yellow);
    firstCalls.datasets[2].data.push(data.FirstCalls.Red);
    firstCalls.datasets[0].data.push(data.FirstCalls.Green);
    firstCalls.labels[0] =
      data.FirstCalls.Green + data.FirstCalls.Red + data.FirstCalls.Yellow;

    fulfillment.datasets[2].data.push(
      data.Fulfillment.Red + data.Fulfillment.Gray
    );
    fulfillment.datasets[1].data.push(data.Fulfillment.Yellow);
    fulfillment.datasets[0].data.push(data.Fulfillment.Green);
    fulfillment.datasets[3].data.push(0);
    fulfillment.labels[0] =
      data.Fulfillment.Red +
      data.Fulfillment.Yellow +
      data.Fulfillment.Green +
      data.Fulfillment.Gray;

    const designMapStagesLabels = [
      ...new Set([
        ...Object.keys(data.DesignStagesRed),
        ...Object.keys(data.DesignStagesYellow),
        ...Object.keys(data.DesignStagesGreen),
        ...Object.keys(data.MapStagesRed),
        ...Object.keys(data.MapStagesYellow),
        ...Object.keys(data.MapStagesGreen),
      ]),
    ].reverse();
    designStages.labels = designMapStagesLabels;
    mapStages.labels = designMapStagesLabels;

    for (const k of designMapStagesLabels) {
      designStages.datasets[2].data.push(data.DesignStagesRed[k]);
      designStages.datasets[1].data.push(data.DesignStagesYellow[k]);
      designStages.datasets[0].data.push(data.DesignStagesGreen[k]);
    }
    for (const k of designMapStagesLabels) {
      mapStages.datasets[2].data.push(data.MapStagesRed[k]);
      mapStages.datasets[1].data.push(data.MapStagesYellow[k]);
      mapStages.datasets[0].data.push(data.MapStagesGreen[k]);
    }

    /*
      "DM - NEW",
      "Active - Initial",
      "Active - Renewed",
      "Active - Additional",
      "Active - Determine Relationship",
    */
    let totalBOB = 0;
    for (const status in data.BOB) {
      const ds = bob.datasets.find((item) => item.label === status);
      if (ds !== undefined) {
        ds.data.push(data.BOB[status]);
        totalBOB += data.BOB[status];
      }
    }
    bob.labels[0] = totalBOB;

    _groupTasks(data.OpenTasks, openTasks, true);
    _groupTasks(data.OpenTasks, overdueTasks, false);

    ///////// CHANGE SOME COLORS TO ORANGE
    bookHygieneNoOrders.datasets[2].backgroundColor = orangeColor;
    bookHygieneNoOrders.datasets[2].borderColor = orangeColor;
    fulfillment.datasets[2].backgroundColor = orangeColor;
    fulfillment.datasets[2].borderColor = orangeColor;
    designStages.datasets[2].backgroundColor = orangeColor;
    designStages.datasets[2].borderColor = orangeColor;
    mapStages.datasets[2].backgroundColor = orangeColor;
    mapStages.datasets[2].borderColor = orangeColor;
    bob.datasets[bob.datasets.length - 1].backgroundColor = yellowColor;
    bob.datasets[bob.datasets.length - 1].borderColor = yellowBorderColor;

    userOptionsCSC.push({ value: "null", label: "All Users" });

    for (const u of data.Coordinators) {
      userOptionsCSC.push({ value: u.Id, label: u.Name });
    }

    if (context.CSCId)
      context.CSC = userOptionsCSC.find((item) => item.value === context.CSCId);

    context.isLoading = false;
  }

  return (
    <>
      {error && <ErrorMessage />}
      {!error && (
        <>
          <Sidebar />
          <div className="content dashboard">
            {!data && <Loader />}
            {data && (
              <>
                <Filter
                  context={context}
                  userOptionsCSC={userOptionsCSC}
                  filter={(e, setShowFilter) => {
                    e.target.disabled = true;
                    e.target.innerHTML = "Loading...";
                    window.location.reload();
                  }}
                />
                <div className="chart-container chart-container-75-25">
                  <div className="cs-title">
                    {!context.CSCId || context.CSC.value === "null"
                      ? "All Users"
                      : context.CSC.label}
                    <p className="cs-legend">
                      <span
                        className="square"
                        style={{ background: greenColor }}
                      ></span>
                      <span>Completed / On Time</span>
                      <span
                        className="square"
                        style={{ background: yellowColor }}
                      ></span>
                      <span>Needs Attention</span>
                      <span
                        className="square"
                        style={{ background: redColor }}
                      ></span>
                      <span>Urgent / Overdue</span>
                      <span
                        className="square"
                        style={{ background: orangeColor }}
                      ></span>
                      <span>Out of My Control</span>
                    </p>
                  </div>
                  <div
                    style={{
                      display: "grid",
                      gap: "5px",
                      gridTemplateColumns: "19% 19% 19% 19% 19%",
                    }}
                  >
                    <div
                      className="chart-col"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        openURLInNewTab(
                          "https://gargle.lightning.force.com/lightning/r/Report/00O2T000004aJO0UAM/view"
                        );
                      }}
                    >
                      <DMStackedChart
                        subtitle="RR Call Prep"
                        data={rrTasks}
                        toolTip={`Shows upcoming Results Review Calls and the status of the call prep. `}
                        darkFont={darkFont}
                      />
                    </div>
                    <div
                      className="chart-col"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        window.location.href =
                          "/report?action=getNoOrders&report=AccountsWithNoOrders&fields=PlanType_OrderNumber&fieldnames=Plan Type_Planned Orders&returnto=/coordinators";
                      }}
                    >
                      <DMStackedChart
                        subtitle="Accounts W/O Next Orders"
                        data={bookHygieneNoOrders}
                        toolTip={`Any Account who may need more orders created, based on their plan type.`}
                        darkFont={darkFont}
                      />
                    </div>
                    <div
                      className="chart-col"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        window.location.href =
                          "/report?action=getNoActivities&report=AccountsWithNoTasks&fields=TaskNumber&fieldnames=Number of Open Tasks&returnto=/coordinators";
                      }}
                    >
                      <DMStackedChart
                        subtitle="No Open Activities"
                        data={bookHygieneNoActivities}
                        darkFont={darkFont}
                        toolTip={`Shows all accounts with no open activity`}
                      />
                    </div>
                    <div
                      className="chart-col"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        openURLInNewTab(
                          "https://gargle.lightning.force.com/lightning/r/Report/00O2T000004aJOKUA2/view"
                        );
                      }}
                    >
                      <DMStackedChart
                        subtitle="Completion of SAI tasks"
                        data={saiDue}
                        darkFont={darkFont}
                        toolTip={`Shows any completed RR tasks so that CSC can make sure the action items are completed on time.`}
                      />
                    </div>
                    <div
                      className="chart-col"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        openURLInNewTab(
                          "https://gargle.lightning.force.com/lightning/r/Report/00O2T000004aJPSUA2/view"
                        );
                      }}
                    >
                      <DMStackedChart
                        subtitle="BOB Report by Status"
                        data={bob}
                        darkFont={darkFont}
                        toolTip={`Shows all active accounts by account status.`}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "grid",
                      gap: "5px",
                      gridTemplateColumns: "50% 50%",
                    }}
                  >
                    <div
                      className="chart-col"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        window.location.href =
                          "/report?action=getFirstCallTasks&report=FirstCallTasks&fields=TaskSubject_TaskStatus_TaskDate_OrderNumber&fieldnames=Task Subject_Task Status_Task Date_Num of Orders&returnto=/coordinators";
                      }}
                    >
                      <DMStackedChart
                        subtitle="1st Call Tasks"
                        data={firstCalls}
                        darkFont={darkFont}
                        toolTip={`Any order hitting homes this week, and the corresponding first call task.`}
                      />
                    </div>
                    <div
                      className="chart-col"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        openURLInNewTab(
                          "https://gargle.lightning.force.com/lightning/r/Report/00O2T000004aJCOUA2/view"
                        );
                      }}
                    >
                      <DMStackedChart
                        subtitle="Fulfillment Status"
                        data={fulfillment}
                        darkFont={darkFont}
                        toolTip={`Shows fulfillment status of all orders hitting homes this week`}
                      />
                    </div>
                  </div>
                </div>
                <div className="chart-container chart-container-33">
                  <div
                    className="chart-col chart-col-33"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      openURLInNewTab(
                        context.CSC === undefined ||
                          context.CSC.label.indexOf("None") !== -1 ||
                          context.CSC.label.indexOf("All Users") !== -1
                          ? "https://gargle.lightning.force.com/lightning/r/Report/00O2T000004aJOtUAM/view"
                          : context.CSC.label.indexOf("Judy") !== -1
                          ? "https://gargle.lightning.force.com/lightning/o/Order/list?filterName=00B2E00000EyHWbUAN"
                          : "https://gargle.lightning.force.com/lightning/o/Order/list?filterName=00B2E00000EyHWcUAN"
                      );
                    }}
                  >
                    <DMPieChart
                      subtitle="In Review Team - Design"
                      data={proofreading}
                      height="300px"
                      darkFont={darkFont}
                      toolTip={`Any orders ready to be proofread by the coordinator.`}
                    />
                  </div>
                  <div
                    className="chart-col chart-col-33"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      openURLInNewTab(
                        "https://gargle.lightning.force.com/lightning/r/Report/00O2T000004aJP9UAM/view"
                      );
                    }}
                  >
                    <DMStackedChart
                      subtitle="Design Stages"
                      data={designStages}
                      darkFont={darkFont}
                      toolTip={`Shows all orders slated for the next 7 weeks, sorted by design stage.`}
                    />
                  </div>
                  <div
                    className="chart-col chart-col-33"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      openURLInNewTab(
                        "https://gargle.lightning.force.com/lightning/r/Report/00O2T000004aJP8UAM/view"
                      );
                    }}
                  >
                    <DMStackedChart
                      subtitle="Map Stages"
                      data={mapStages}
                      darkFont={darkFont}
                      toolTip={`Shows all orders slated for the next 7 weeks, sorted by map stage.`}
                    />
                  </div>
                </div>

                <OpenTasksTable
                  openTasks={openTasks}
                  overdueTasks={overdueTasks}
                />
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default CSC;
