import * as XLSX from "xlsx";
import Papa from "papaparse";
import { mathSign } from "recharts/lib/util/DataUtils";

export const states = [
  ["Arizona", "AZ"],
  ["Alabama", "AL"],
  ["Alaska", "AK"],
  ["Arkansas", "AR"],
  ["California", "CA"],
  ["Colorado", "CO"],
  ["Connecticut", "CT"],
  ["Delaware", "DE"],
  ["Florida", "FL"],
  ["Georgia", "GA"],
  ["Hawaii", "HI"],
  ["Idaho", "ID"],
  ["Illinois", "IL"],
  ["Indiana", "IN"],
  ["Iowa", "IA"],
  ["Kansas", "KS"],
  ["Kentucky", "KY"],
  ["Louisiana", "LA"],
  ["Maine", "ME"],
  ["Maryland", "MD"],
  ["Massachusetts", "MA"],
  ["Michigan", "MI"],
  ["Minnesota", "MN"],
  ["Mississippi", "MS"],
  ["Missouri", "MO"],
  ["Montana", "MT"],
  ["Nebraska", "NE"],
  ["Nevada", "NV"],
  ["New Hampshire", "NH"],
  ["New Jersey", "NJ"],
  ["New Mexico", "NM"],
  ["New York", "NY"],
  ["North Carolina", "NC"],
  ["North Dakota", "ND"],
  ["Ohio", "OH"],
  ["Oklahoma", "OK"],
  ["Oregon", "OR"],
  ["Pennsylvania", "PA"],
  ["Rhode Island", "RI"],
  ["South Carolina", "SC"],
  ["South Dakota", "SD"],
  ["Tennessee", "TN"],
  ["Texas", "TX"],
  ["Utah", "UT"],
  ["Vermont", "VT"],
  ["Virginia", "VA"],
  ["Washington", "WA"],
  ["West Virginia", "WV"],
  ["Wisconsin", "WI"],
  ["Wyoming", "WY"],
];

export const getUrlParameter = (paramName) => {
  let paramValue = null;
  let params = new URLSearchParams(window.location.search);

  for (let p of params) {
    if (p[0].toLocaleLowerCase() === paramName) {
      paramValue = p[1];
    }
  }

  return paramValue;
};

// process CSV data
export const processData = (dataString, setData, setColumns) => {
  const parsed = Papa.parse(dataString, { header: true });
  const headers = parsed.meta.fields;
  const list = parsed.data;

  const columns = headers.map((item) => {
    return {
      name: item,
      selector: (row) => row[item],
      sortable: true,
    };
  });

  setData(list);
  setColumns(columns);
};

export const customTableSort = (rows, selector, direction) => {
  return rows.sort((rowA, rowB) => {
    const aField = selector(rowA);
    const bField = selector(rowB);

    let comparison = aField > bField ? 1 : aField < bField ? -1 : 0;

    try {
      comparison =
        new Date(aField) > new Date(bField)
          ? 1
          : new Date(aField) < new Date(bField)
          ? -1
          : 0;
    } catch (err) {}

    return direction === "desc" ? comparison * -1 : comparison;
  });
};

// handle file upload
export const handleFileUpload = (file, setData, setColumns) => {
  const reader = new FileReader();
  reader.onload = (evt) => {
    /* Parse data */
    const bstr = evt.target.result;
    const wb = XLSX.read(bstr, { type: "binary" });
    /* Get first worksheet */
    const wsname = wb.SheetNames[0];
    const ws = wb.Sheets[wsname];
    /* Convert array of arrays */
    const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
    processData(data, setData, setColumns);
  };
  reader.readAsBinaryString(file);
};

export const csvArraysToObject = (csvArray) => {
  const cols = csvArray.shift();
  const result = [];

  for (const line of csvArray) {
    const obj = {};
    for (let i = 0; i < line.length; i++) {
      obj[cols[i]] = line[i];
    }
    result.push(obj);
  }

  return result;
};

export const getDate = (ini) => {
  return ini
    ? new Date(
        new Date(ini).getUTCFullYear(),
        new Date(ini).getUTCMonth(),
        new Date(ini).getUTCDate()
      )
    : new Date(
        new Date().getUTCFullYear(),
        new Date().getUTCMonth(),
        new Date().getUTCDate()
      );
};

export const dayDiff = (ini, end) => {
  return Math.round((getDate(ini) - getDate(end)) / (1000 * 60 * 60 * 24));
};

export const minutesDiff = (ini, end) => {
  return Math.round((getDate(ini) - getDate(end)) / (1000 * 60));
};

export const upperCaseText = (t) => {
  return t ? t.toString().toUpperCase() : "";
};

export const getMonthName = (i) => {
  const d = new Date();
  d.setMonth(i);
  return d.toLocaleString("default", { month: "long" });
};

export const openURLInNewTab = (url) => {
  const a = document.createElement("a");
  a.href = url;
  a.target = "_blank";
  a.click();
};

export const getStorageValue = (k) => {
  const v = window.localStorage.getItem(k);
  return !v || v === "" || v === "null" ? null : v;
};
