import React, { useContext } from "react";
import { UserContext } from "../../App";

import Sidebar from "../Sidebar";
import background from "../../background.jpg";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartArea,
  faChartBar,
  faChartLine,
  faChartPie,
} from "@fortawesome/free-solid-svg-icons";

const Home = () => {
  const context = useContext(UserContext);
  if (context.accessToken === null) return <></>;

  return (
    <>
      <Sidebar />
      <div className="background">
        <img src={background} alt="background" />
      </div>
      <div className="content absolute">
        <div className="home">
          <h1 className="blue">Dentalmarketing Analytics</h1>
          <h4>Select the option which best fit your search</h4>
          <br />
          <div className="menu">
            <NavLink exact={true} activeClassName="is-active" to="/overview">
              <FontAwesomeIcon icon={faChartPie} />
              Company Overview
            </NavLink>
            <NavLink
              exact={true}
              activeClassName="is-active"
              to="/coordinators"
            >
              <FontAwesomeIcon icon={faChartBar} />
              Coordinator Dashboard
            </NavLink>
            <NavLink exact={true} activeClassName="is-active" to="/directors">
              <FontAwesomeIcon icon={faChartArea} />
              Director Dashboard
            </NavLink>
            <NavLink exact={true} activeClassName="is-active" to="/vp">
              <FontAwesomeIcon icon={faChartLine} />
              VP Dashboard
            </NavLink>
            <NavLink exact={true} activeClassName="is-active" to="/progress">
              <FontAwesomeIcon icon={faChartLine} />
              Progress Report
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
