import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../App";

import Sidebar from "../Sidebar";
import { getVP } from "../DMData";
import { DMPieChart, DMStackedChart } from "../DMCharts";
import Loader from "../Loader";
import ErrorMessage from "../ErrorMessage";

import {
  _BOBbyPlan,
  _BOBbyStatus,
  _bookHygieneNoOrders,
  _bookHygieneNoPayment,
  _groupTasks,
  _inactiveAdditional,
  _lastMinute,
  _noStart,
  _upcomingRR,
} from "../CSInit";

import { getStorageValue, openURLInNewTab } from "../Utils";
import Filter from "../Filter";
import OpenTasksTable from "../OpenTasksTable";

const Design = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(false);

  const openTasks = [];
  const overdueTasks = [];
  const userOptionsVPs = [];

  const bookHygieneNoOrdersPC = _bookHygieneNoOrders();
  const bookHygieneNoOrdersNM = _bookHygieneNoOrders();
  const bookHygieneNoPaymentPC = _bookHygieneNoPayment();
  const bookHygieneNoPaymentNM = _bookHygieneNoPayment();
  const noStart = _noStart();
  const inactiveAdditional = _inactiveAdditional();
  const lastMinute = _lastMinute();
  const upcomingRR = _upcomingRR();

  const bob = _BOBbyStatus();
  const bobbyplan = _BOBbyPlan();

  const darkFont = true;

  const context = useContext(UserContext);

  const refresh = (load) => {
    if (context.accessToken !== null && load && !context.isLoading) {
      context.isLoading = true;
      getVP(context).then(function (result) {
        refreshData(result);
      });
    }
  };

  const refreshData = (result) => {
    if (result === null) setError(true);
    else {
      setData(result);
      setError(false);
    }
  };

  useEffect(() => {
    refresh(!data);
  }); // Initial page load

  if (context.accessToken === null) return <></>;

  if (getStorageValue("dpm_analytics_owner_id"))
    context.OwnerId = getStorageValue("dpm_analytics_owner_id");

  if (getStorageValue("dpm_analytics_vp_id"))
    context.VPId = getStorageValue("dpm_analytics_vp_id");

  if (data) {
    bookHygieneNoOrdersPC.datasets[2].data.push(data.NoOrdersPC.Red);
    bookHygieneNoOrdersPC.datasets[1].data.push(data.NoOrdersPC.Yellow);
    bookHygieneNoOrdersPC.datasets[0].data.push(data.NoOrdersPC.Green);

    bookHygieneNoOrdersNM.datasets[2].data.push(data.NoOrdersNM.Red);
    bookHygieneNoOrdersNM.datasets[1].data.push(data.NoOrdersNM.Yellow);
    bookHygieneNoOrdersNM.datasets[0].data.push(data.NoOrdersNM.Green);

    bookHygieneNoPaymentPC.datasets[2].data.push(data.NoPaymentPC.Red);
    bookHygieneNoPaymentPC.datasets[1].data.push(data.NoPaymentPC.Yellow);
    bookHygieneNoPaymentPC.datasets[0].data.push(data.NoPaymentPC.Green);

    bookHygieneNoPaymentNM.datasets[2].data.push(data.NoPaymentNM.Red);
    bookHygieneNoPaymentNM.datasets[1].data.push(data.NoPaymentNM.Yellow);
    bookHygieneNoPaymentNM.datasets[0].data.push(data.NoPaymentNM.Green);

    noStart.datasets[2].data.push(data.NoStart.Red);
    noStart.datasets[1].data.push(data.NoStart.Yellow);
    noStart.datasets[0].data.push(data.NoStart.Green);

    inactiveAdditional.datasets[2].data.push(data.InactiveAdditional.Red);
    inactiveAdditional.datasets[1].data.push(data.InactiveAdditional.Yellow);
    inactiveAdditional.datasets[0].data.push(data.InactiveAdditional.Green);

    lastMinute.datasets[0].data.push(data.LastMinute.Red);

    upcomingRR.datasets[2].data.push(data.UpcomingRR.Red);
    upcomingRR.datasets[1].data.push(data.UpcomingRR.Yellow);
    upcomingRR.datasets[0].data.push(data.UpcomingRR.Green);

    /*
      "DM - NEW",
      "Active - Initial",
      "Active - Renewed",
      "Active - Additional",
      "Active - Determine Relationship",
    */
    let totalBOB = 0;
    bob.datasets[0].data.push(data.BOB["DM - NEW"]);
    bob.datasets[0].data.push(data.BOB["Active - Initial"]);
    bob.datasets[0].data.push(data.BOB["Active - Renewed"]);
    bob.datasets[0].data.push(data.BOB["Active - Additional"]);
    bob.datasets[0].data.push(data.BOB["Active - Determine Relationship"]);
    for (const status in data.BOB) {
      totalBOB += data.BOB[status];
    }
    bob.datasets[0].label = totalBOB;

    /*
      "On Plan",
      "Deviating",
      "One-Off",
      "No Plan",
    */
    let totalBOBbyPlan = 0;
    bobbyplan.datasets[0].data.push(data.BOBbyPlan["On Plan"]);
    bobbyplan.datasets[0].data.push(data.BOBbyPlan["Deviating"]);
    bobbyplan.datasets[0].data.push(data.BOBbyPlan["One-Off"]);
    bobbyplan.datasets[0].data.push(data.BOBbyPlan["No Plan"]);
    for (const plan in data.BOBbyPlan) {
      totalBOBbyPlan += data.BOBbyPlan[plan];
    }
    bobbyplan.datasets[0].label = totalBOBbyPlan;

    _groupTasks(data.OpenTasks, openTasks, true);
    _groupTasks(data.OpenTasks, overdueTasks, false);

    userOptionsVPs.push({ value: "null", label: "- None -" });

    for (const u of data.VPs) {
      userOptionsVPs.push({ value: u.Id, label: u.Name });
    }

    if (context.VPId)
      context.VP = userOptionsVPs.find((item) => item.value === context.VPId);
  }

  return (
    <>
      {error && <ErrorMessage />}
      {!error && (
        <>
          <Sidebar />
          <div className="content dashboard">
            {!data && <Loader />}
            {data && (
              <>
                <Filter
                  context={context}
                  userOptionsVPs={userOptionsVPs}
                  filter={(e, setShowFilter) => {
                    getVP(context)
                      .then(function (result) {
                        setData(result);
                      })
                      .finally(function () {
                        e.target.disabled = false;
                        e.target.innerHTML = "Filter";
                        setShowFilter(false);
                      });
                    e.target.disabled = true;
                    e.target.innerHTML = "Loading...";
                  }}
                />
                <div
                  className="chart-container chart-col-100"
                  style={{
                    gridTemplateColumns: "15% 15% 15% 15% 18% 18%",
                  }}
                >
                  <p className="cs-title">
                    {!context.OwnerId || context.Owner.value === "null"
                      ? "All Users"
                      : context.Owner.label}
                  </p>
                  <div
                    className="chart-col"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      openURLInNewTab(
                        "https://gargle.lightning.force.com/lightning/r/Report/00O2T000007AsPCUA0/view?queryScope=userFolders"
                      );
                    }}
                  >
                    <DMStackedChart
                      subtitle="Accounts W/O Next Orders (PC)"
                      data={bookHygieneNoOrdersPC}
                      toolTip={`Any Account who may need more orders created, based on their plan type.`}
                    />
                  </div>
                  <div
                    className="chart-col"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      openURLInNewTab(
                        "https://gargle.lightning.force.com/lightning/r/Report/00O2T000007AsPCUA0/view?queryScope=userFolders"
                      );
                    }}
                  >
                    <DMStackedChart
                      subtitle="Accounts W/O Next Orders (NM)"
                      data={bookHygieneNoOrdersNM}
                      toolTip={`Any Account who may need more orders created, based on their plan type.`}
                    />
                  </div>
                  <div
                    className="chart-col"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      openURLInNewTab(
                        "https://gargle.lightning.force.com/lightning/r/Report/00O2T000007AsPHUA0/view?queryScope=userFolders"
                      );
                    }}
                  >
                    <DMStackedChart
                      subtitle="No Payment (PC)"
                      data={bookHygieneNoPaymentPC}
                      darkFont={darkFont}
                    />
                  </div>
                  <div
                    className="chart-col"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      openURLInNewTab(
                        "https://gargle.lightning.force.com/lightning/r/Report/00O2T000007AsPMUA0/view?queryScope=userFolders"
                      );
                    }}
                  >
                    <DMStackedChart
                      subtitle="No Payment (NM)"
                      data={bookHygieneNoPaymentNM}
                      darkFont={darkFont}
                    />
                  </div>

                  <div
                    className="chart-col"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      openURLInNewTab(
                        "https://gargle.lightning.force.com/lightning/r/Report/00O2T000004aJPSUA2/view"
                      );
                    }}
                  >
                    <DMPieChart
                      subtitle="BOB Report by Status"
                      data={bob}
                      darkFont={darkFont}
                      toolTip={`Shows all active accounts by account status.`}
                      isDoughnut={true}
                    />
                  </div>
                  <div
                    className="chart-col"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      openURLInNewTab(
                        "https://gargle.lightning.force.com/lightning/r/Report/00O2T000004aJPSUA2/view"
                      );
                    }}
                  >
                    <DMPieChart
                      subtitle="BOB Report by Plan"
                      data={bobbyplan}
                      darkFont={darkFont}
                      toolTip={`Shows all active accounts by account plan.`}
                      isDoughnut={true}
                    />
                  </div>
                </div>

                <div
                  className="chart-container chart-col-100"
                  style={{
                    gridTemplateColumns: "19% 19% 19% 19% 19%",
                  }}
                >
                  <div className="chart-col stats">
                    <h4>RR Tasks Completed Today</h4>
                    <h5>{data.RRCompletedToday}</h5>
                    <h4>RR Tasks Completed This Month</h4>
                    <h5>{data.RRCompletedThisMonth}</h5>
                    <h4>RR Tasks Planned This Month</h4>
                    <h5>{data.RRPlanned}</h5>
                  </div>
                  <div
                    className="chart-col"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      openURLInNewTab(
                        "https://gargle.lightning.force.com/lightning/r/sObject/00O2T000007AoQVUA0/view?queryScope=userFolders"
                      );
                    }}
                  >
                    <DMStackedChart
                      subtitle="No Starts"
                      data={noStart}
                      darkFont={darkFont}
                    />
                  </div>
                  <div
                    className="chart-col"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      openURLInNewTab(
                        "https://gargle.lightning.force.com/lightning/r/sObject/00O2T000007AoQVUA0/view?queryScope=userFolders"
                      );
                    }}
                  >
                    <DMStackedChart
                      subtitle="Inactive - Additional Accounts"
                      data={inactiveAdditional}
                      darkFont={darkFont}
                    />
                  </div>
                  <div
                    className="chart-col"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      openURLInNewTab(
                        "https://gargle.lightning.force.com/lightning/r/sObject/00O2T000007AoQVUA0/view?queryScope=userFolders"
                      );
                    }}
                  >
                    <DMStackedChart
                      subtitle="Last Minute Approvals"
                      data={lastMinute}
                      darkFont={darkFont}
                    />
                  </div>
                  <div
                    className="chart-col"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      openURLInNewTab(
                        "https://gargle.lightning.force.com/lightning/r/sObject/00O2T000007AoQVUA0/view?queryScope=userFolders"
                      );
                    }}
                  >
                    <DMStackedChart
                      subtitle="Upcoming RR Calls"
                      data={upcomingRR}
                      darkFont={darkFont}
                    />
                  </div>
                </div>

                <OpenTasksTable
                  openTasks={openTasks}
                  overdueTasks={overdueTasks}
                />
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default Design;
