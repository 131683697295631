import { rest } from "../API";

export const getOverview = async (context) => {
  const result = await rest(
    "Overview",
    "DMAnalytics",
    "Overview",
    {
      FilterByOwner: context.OwnerId === "null" ? null : context.OwnerId,
      FilterByVP: context.VPId === "null" ? null : context.VPId,
      FilterByCSC: context.CSCId === "null" ? null : context.CSCId,
    },
    context
  );
  return JSON.parse(result);
};

export const getCSC = async (context) => {
  const result = await rest(
    "CSC",
    "DMAnalytics",
    "CSC",
    {
      FilterByCSC: context.CSCId === "null" ? null : context.CSCId,
      FilterByOwner: null,
      FilterByVP: null,
    },
    context
  );
  return JSON.parse(result);
};

export const getCSD = async (context) => {
  const result = await rest(
    "CSD",
    "DMAnalytics",
    "CSD",
    {
      FilterByOwner: context.OwnerId === "null" ? null : context.OwnerId,
      FilterByCSC: null,
      FilterByVP: null,
    },
    context
  );
  return JSON.parse(result);
};

export const getVP = async (context) => {
  const result = await rest(
    "VP",
    "DMAnalytics",
    "VP",
    {
      FilterByOwner: null,
      FilterByCSC: null,
      FilterByVP: context.VPId === "null" ? null : context.VPId,
    },
    context
  );
  return JSON.parse(result);
};

export const getDesign = async (context) => {
  const result = await rest(
    "Design",
    "DMAnalytics",
    "Design",
    {
      FilterByOwner: null,
      FilterByCSC: null,
      FilterByVP: null,
    },
    context
  );
  return JSON.parse(result);
};

export const getNoOrders = async (context) => {
  const result = await rest(
    "AccountsWithNoOrders",
    "DMAnalytics",
    "AccountsWithNoOrders",
    {
      FilterByOwner: null,
      FilterByCSC:
        !context.CSCId || context.CSCId === "null" ? null : context.CSCId,
      FilterByVP: null,
    },
    context
  );
  return JSON.parse(result);
};

export const getNoActivities = async (context) => {
  const result = await rest(
    "AccountsWithNoActivities",
    "DMAnalytics",
    "AccountsWithNoActivities",
    {
      FilterByOwner: null,
      FilterByCSC:
        !context.CSCId || context.CSCId === "null" ? null : context.CSCId,
      FilterByVP: null,
    },
    context
  );
  return JSON.parse(result);
};

export const getFirstCallTasks = async (context) => {
  const result = await rest(
    "FirstCallTasks",
    "DMAnalytics",
    "FirstCallTasks",
    {
      FilterByOwner: null,
      FilterByCSC:
        !context.CSCId || context.CSCId === "null" ? null : context.CSCId,
      FilterByVP: null,
    },
    context
  );
  return JSON.parse(result);
};

export const getRR = async (context) => {
  const result = await rest(
    "RR",
    "DMAnalytics",
    "RR",
    {
      FilterByOwner:
        !context.OwnerId || context.OwnerId === "null" ? null : context.OwnerId,
      FilterByCSC:
        !context.CSCId || context.CSCId === "null" ? null : context.CSCId,
      FilterByVP:
        !context.VPId || context.VPId === "null" ? null : context.VPId,
    },
    context
  );
  return JSON.parse(result);
};

export const getPROGRESS = async (context) => {
  const result = await rest(
    "PROGRESS",
    "DMAnalytics",
    "PROGRESS",
    {
      FilterByOwner:
        !context.OwnerId || context.OwnerId === "null" ? null : context.OwnerId,
      FilterByCSC: null,
      FilterByVP: null,
    },
    context
  );
  return JSON.parse(result);
};

export const getINACTIVEADD = async (context) => {
  const result = await rest(
    "INACTIVEADD",
    "DMAnalytics",
    "INACTIVEADD",
    {
      FilterByOwner:
        !context.OwnerId || context.OwnerId === "null" ? null : context.OwnerId,
      FilterByCSC: null,
      FilterByVP: null,
    },
    context
  );
  return JSON.parse(result);
};

export const getNOORDERSPC = async (context) => {
  const result = await rest(
    "NOORDERS",
    "DMAnalytics",
    "NOORDERS",
    {
      FilterByVP:
        !context.VPId || context.VPId === "null" ? null : context.VPId,
      Type: "Postcard Mailing",
    },
    context
  );
  return JSON.parse(result);
};

export const getNOORDERSNM = async (context) => {
  const result = await rest(
    "NOORDERS",
    "DMAnalytics",
    "NOORDERS",
    {
      FilterByVP:
        !context.VPId || context.VPId === "null" ? null : context.VPId,
      Type: "New Move-In",
    },
    context
  );
  return JSON.parse(result);
};

export const getPAIDLASTMONTHSPC = async (context) => {
  const result = await rest(
    "PAIDLASTMONTH",
    "DMAnalytics",
    "PAIDLASTMONTH",
    {
      FilterByVP:
        !context.VPId || context.VPId === "null" ? null : context.VPId,
      Type: "Postcard Mailing",
    },
    context
  );
  console.log(result);
  return JSON.parse(result);
};

export const getPAIDLASTMONTHNM = async (context) => {
  const result = await rest(
    "PAIDLASTMONTH",
    "DMAnalytics",
    "PAIDLASTMONTH",
    {
      FilterByVP:
        !context.VPId || context.VPId === "null" ? null : context.VPId,
      Type: "New Move-In",
    },
    context
  );
  return JSON.parse(result);
};

export const getAPE = async (context) => {
  const result = await rest(
    "APE",
    "DMAnalytics",
    "APE",
    {
      OrderLabel: context.OrderLabel,
    },
    context
  );
  return JSON.parse(result);
};
