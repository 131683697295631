import React from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faChartBar,
  faChartArea,
  faChartPie,
  faChartLine,
} from "@fortawesome/free-solid-svg-icons";

const Sidebar = () => {
  return (
    <div className="sidebar">
      <NavLink exact={true} activeClassName="is-active" to="/">
        <FontAwesomeIcon icon={faHome} />
        Home
      </NavLink>
      <NavLink exact={true} activeClassName="is-active" to="/overview">
        <FontAwesomeIcon icon={faChartPie} />
        Overview
      </NavLink>
      <NavLink exact={true} activeClassName="is-active" to="/coordinators">
        <FontAwesomeIcon icon={faChartBar} />
        Coordinator Dashboard
      </NavLink>
      <NavLink exact={true} activeClassName="is-active" to="/directors">
        <FontAwesomeIcon icon={faChartArea} />
        Director Dashboard
      </NavLink>
      <NavLink exact={true} activeClassName="is-active" to="/vp">
        <FontAwesomeIcon icon={faChartLine} />
        VP Dashboard
      </NavLink>
      <NavLink exact={true} activeClassName="is-active" to="/design">
        <FontAwesomeIcon icon={faChartLine} />
        Design Dashboard
      </NavLink>
      <NavLink exact={true} activeClassName="is-active" to="/progress">
        <FontAwesomeIcon icon={faChartLine} />
        Progress Report
      </NavLink>
    </div>
  );
};

export default Sidebar;
