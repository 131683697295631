import "./App.css";

import React, { createContext } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import Home from "./components/Home";
import { SFAuth } from "./components/SFApi";
import Overview from "./components/Overview";
import CSC from "./components/CSC";
import APE from "./components/APE";
import CSD from "./components/CSD";
import VP from "./components/VP";
import Design from "./components/Design";
import AccountTable from "./components/AccountTable";

export const UserContext = createContext(null);

function App() {
  /////////////////////////////////////////////// USE THIS FLAG TO PUT EVERYTHING IN MAINTENANCE
  const inMaintenance = false;

  if (window.location.href.indexOf("/#") !== -1) {
    window.location.href = window.location.href.replace("/#", "/?");
    console.log("hola");
    return;
  }

  return (
    <>
      {inMaintenance && (
        <div
          style={{
            height: "100%",
            width: "100%",
            textAlign: "center",
            marginTop: "100px",
          }}
        >
          We are doing some maintenance. We will be back soon :)
        </div>
      )}
      {!inMaintenance && (
        <React.StrictMode>
          <UserContext.Provider value={{ accessToken: null, isLoading: false }}>
            <Router forceRefresh>
              <Route path="/" component={SFAuth} />
              <Route path="/" component={Home} exact />
              <Route path="/report" component={AccountTable} exact />
              <Route path="/overview" component={Overview} exact />
              <Route path="/coordinators" component={CSC} exact />
              <Route path="/directors" component={CSD} exact />
              <Route path="/vp" component={VP} exact />
              <Route path="/design" component={Design} exact />
              <Route path="/progress" component={APE} exact />
            </Router>
          </UserContext.Provider>
        </React.StrictMode>
      )}
    </>
  );
}

export default App;
