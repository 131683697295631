import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../App";

import { greenColor, redColor, yellowColor } from "../DMCharts";
import {
  getFirstCallTasks,
  getINACTIVEADD,
  getNoActivities,
  getNoOrders,
  getNOORDERSNM,
  getNOORDERSPC,
  getPAIDLASTMONTHNM,
  getPAIDLASTMONTHSPC,
  getPROGRESS,
  getRR,
} from "../DMData";
import ErrorMessage from "../ErrorMessage";
import Loader from "../Loader";
import Sidebar from "../Sidebar";
import { getStorageValue, getUrlParameter } from "../Utils";

const AccountTable = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(false);
  const [total, setTotal] = useState(null);

  const context = useContext(UserContext);

  const action = getUrlParameter("action");
  const report = getUrlParameter("report");
  const fields = getUrlParameter("fields").split("_");
  const fieldNames = getUrlParameter("fieldnames").split("_");
  const returnTo = getUrlParameter("returnto");

  const colWidth = 100 / (fields.length + 1) + "%";
  const lastField = fields[fields.length - 1];

  if (getStorageValue("dpm_analytics_csc_id"))
    context.CSCId = getStorageValue("dpm_analytics_csc_id");

  if (getStorageValue("dpm_analytics_vp_id"))
    context.VPId = getStorageValue("dpm_analytics_vp_id");

  if (getStorageValue("dpm_analytics_owner_id"))
    context.OwnerId = getStorageValue("dpm_analytics_owner_id");

  const refresh = (load) => {
    if (context.accessToken !== null && load && !context.isLoading) {
      context.isLoading = true;
      const promise =
        action === "getNoOrders"
          ? getNoOrders(context)
          : action === "getNoActivities"
          ? getNoActivities(context)
          : action === "getFirstCallTasks"
          ? getFirstCallTasks(context)
          : action === "getRR"
          ? getRR(context)
          : action === "getPROGRESS"
          ? getPROGRESS(context)
          : action === "getINACTIVEADD"
          ? getINACTIVEADD(context)
          : action === "getNOORDERSPC"
          ? getNOORDERSPC(context)
          : action === "getNOORDERSNM"
          ? getNOORDERSNM(context)
          : action === "getPAIDLASTMONTHPC"
          ? getPAIDLASTMONTHSPC(context)
          : action === "getPAIDLASTMONTHNM"
          ? getPAIDLASTMONTHNM(context)
          : new Promise((resolve, reject) => {
              reject("No Function");
            });
      promise.then(function (result) {
        refreshData(result[report]);
      });
    }
  };

  const refreshData = (result) => {
    if (result === null) setError(true);
    else {
      setData(result);
      setTotal(
        result.Green.length +
          result.Red.length +
          (result.Yellow ? result.Yellow.length : 0)
      );
      for (const color in result) {
        result[color].sort((a, b) => a[lastField] - b[lastField]);
      }
      setError(false);
    }
  };

  useEffect(() => {
    refresh(!data);
  }); // Initial page load

  const addRow = (item, cls, key) => {
    const backColor =
      cls === "red" ? redColor : cls === "yellow" ? yellowColor : greenColor;
    return (
      <div className="report-row" key={key} style={{ background: backColor }}>
        <div className="report-col" style={{ width: colWidth }}>
          <a
            href={"https://gargle.lightning.force.com/" + item.Id}
            target="_blank"
            rel="noreferrer"
          >
            {item.Name}
          </a>
        </div>
        {fields.map((field, index) => {
          console.log(item.OrderId);
          return (
            <>
              {field !== "OrderLabel" && (
                <div
                  className="report-col"
                  style={{ width: colWidth }}
                  key={"col-" + index + "-" + key}
                >
                  {item[field]}
                </div>
              )}
              {field === "OrderLabel" && (
                <div
                  className="report-col"
                  style={{ width: colWidth }}
                  key={"col-" + index + "-" + key}
                >
                  <a
                    href={"https://gargle.lightning.force.com/" + item.OrderId}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {item.OrderLabel}
                  </a>
                </div>
              )}
            </>
          );
        })}
      </div>
    );
  };

  return (
    <>
      {error && <ErrorMessage />}
      {!error && (
        <>
          <Sidebar />
          <div className="content dashboard">
            {!data && <Loader />}
            {data && (
              <>
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    window.location.href = returnTo;
                  }}
                  style={{ marginTop: "10px" }}
                >
                  Back to Dashboard
                </button>
                <button
                  className="btn-refresh"
                  onClick={() => window.location.reload()}
                  style={{ float: "right", marginTop: "10px" }}
                >
                  Refresh
                </button>
                <div className="report-table">
                  <div
                    className="report-col report-header"
                    style={{ width: colWidth }}
                  >
                    Name
                  </div>
                  {fieldNames.map((item, index) => {
                    return (
                      <div
                        className="report-col report-header"
                        style={{ width: colWidth }}
                        key={"header-" + index}
                      >
                        {item}
                      </div>
                    );
                  })}
                  {data.Red &&
                    data.Red.map((item, index) => {
                      return addRow(item, "red", index + "red");
                    })}
                  {data.Yellow &&
                    data.Yellow.map((item, index) => {
                      return addRow(item, "yellow", index + "yellow");
                    })}
                  {data.Green &&
                    data.Green.map((item, index) => {
                      return addRow(item, "green", index + "red");
                    })}
                  <div
                    style={{
                      width: "100%",
                      display: "block",
                      padding: "10px",
                      textAlign: "right",
                      fontWeight: "bold",
                    }}
                  >
                    Total Number of Records: {total}
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default AccountTable;
