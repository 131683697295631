import React, { useContext, useState } from "react";
import { UserContext } from "../../App";
import { Row, Col, Button, Form } from "react-bootstrap";

import noMissed from "../../nomissed.jpg";
import noClosed from "../../nocloseratio.jpg";

import Sidebar from "../Sidebar";
import { getAPE } from "../DMData";
import Loader from "../Loader";
import DataTable from "react-data-table-component";
import { dayDiff, getDate, getUrlParameter, openURLInNewTab } from "../Utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import dateFormat from "dateformat";
import {
  blueBorderColor,
  borderWidth,
  greenBorderColor,
  redBorderColor,
  yellowBorderColor,
  blueColor,
  DMStackedChart,
  greenColor,
  redColor,
  yellowColor,
} from "../DMCharts";

const APE = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showLastThreeOrders, setShowLastThreeOrders] = useState(false);
  const [orderLabel, setOrderLabel] = useState("");

  const [enableEdit, setEnableEdit] = useState(true);

  const [avgPatientValue, setAvgPatientValue] = useState(0);
  const [uniqueCallers, setUniqueCallers] = useState(0);
  const [newPatients, setNewPatients] = useState(0);
  const [closeRatio, setCloseRatio] = useState(0);
  const [missedCalls, setMissedCalls] = useState(0);

  const [newPatients30Days, setNewPatients30Days] = useState(0);
  const [newPatients60Days, setNewPatients60Days] = useState(0);
  const [newPatients90Days, setNewPatients90Days] = useState(0);
  const [newPatients150Days, setNewPatients150Days] = useState(0);
  const [newPatientsAfter, setNewPatientsAfter] = useState(0);

  const [totalCalls30Days, setTotalCalls30Days] = useState(0);
  const [totalMissedCalls30Days, setTotalMissedCalls30Days] = useState(0);
  const [totalNewPatients30Days, setTotalNewPatients30Days] = useState(0);
  const [projectedPatients, setProjectedPatients] = useState(10);

  let currentMissedCallPercent = 0;
  let currentROI = 0;
  let currentRevenue = 0;

  const context = useContext(UserContext);
  if (context.accessToken === null) return <></>;

  const correctData = data && data.Order && data.Account;

  const missedCallData = {
    labels: ["Your Practice", "National Average"],
    datasets: [
      {
        label: "Missed Calls",
        data: [],
        backgroundColor: [redColor, yellowColor],
        borderColor: [redBorderColor, yellowBorderColor],
        borderWidth: borderWidth,
      },
    ],
  };

  const closingRatioData = {
    labels: ["Your Practice", "National Average"],
    datasets: [
      {
        data: [],
        backgroundColor: [redColor, yellowColor],
        borderColor: [redBorderColor, yellowBorderColor],
        borderWidth: borderWidth,
      },
    ],
  };

  const roiData = {
    labels: ["Current ROI", "ROI at Maturity"],
    datasets: [
      {
        data: [],
        backgroundColor: [greenColor, blueColor],
        borderColor: [blueBorderColor, yellowBorderColor],
        borderWidth: borderWidth,
      },
    ],
  };

  const revenueData = {
    labels: ["Current Revenue", "Revenue at Maturity"],
    datasets: [
      {
        label: ["Revenue"],
        data: [],
        backgroundColor: [greenColor, blueColor],
        borderColor: [blueBorderColor, yellowBorderColor],
        borderWidth: borderWidth,
      },
    ],
  };

  const updateProjectedPatients = (p30, p60, p90, p150, paft) => {
    let diff = dayDiff(getDate(), data.Order.Date_of_1st_Call__c);
    if (diff <= 0) diff = 1;

    const diff30 = diff <= 30 ? (diff * 100) / 30 : 100;
    const diff60 = diff > 30 && diff <= 60 ? (diff * 100) / 60 : 100;
    const diff90 = diff > 60 && diff <= 90 ? (diff * 100) / 90 : 100;
    const diff150 = diff > 90 && diff <= 150 ? (diff * 100) / 150 : 100;

    let newp30 = ((p30 * 100) / diff30) * 2;
    let newp60 = ((p60 * 100) / diff60) * 4;
    let newp90 = ((p90 * 100) / diff90) * 5;
    let newp150 = ((p150 * 100) / diff150) * 5;

    const newp =
      newp30 +
      (newp60 - newp30 > 0 ? newp60 - newp30 : newp60) +
      (newp90 - newp60 > 0 ? newp90 - newp60 : newp90) +
      (newp150 - newp90 > 0 ? newp150 - newp90 : newp150);

    setProjectedPatients(Math.round(newp));
  };

  if (correctData) {
    if (enableEdit) {
      if (data.Account.Average_New_Patient_Value__c !== avgPatientValue)
        setAvgPatientValue(data.Account.Average_New_Patient_Value__c);
      if (data.Order.Total_Unique_Callers__c !== uniqueCallers)
        setUniqueCallers(data.Order.Total_Unique_Callers__c);
      if (data.FirstPatientsCount !== newPatients)
        setCloseRatio(data.Order.Close_Ratio__c);
      if (data.Order.Missed_Calls__c !== missedCalls)
        setMissedCalls(data.Order.Missed_Calls__c);

      let p30 = 0;
      let p60 = 0;
      let p90 = 0;
      let p150 = 0;
      let paft = 0;
      let tot = 0;

      for (const p of data.Patients) {
        const diff = dayDiff(
          p.Last_Seen_Date__c,
          data.Order.Date_of_1st_Call__c
        );

        tot++;
        if (diff <= 30) p30++;
        if (diff > 30 && diff <= 60) p60++;
        if (diff > 60 && diff <= 90) p90++;
        if (diff > 90 && diff <= 150) p150++;
        if (diff > 150) paft++;
      }

      if (tot !== newPatients) setNewPatients(tot);
      if (p30 !== newPatients30Days) setNewPatients30Days(p30);
      if (p60 !== newPatients60Days) setNewPatients60Days(p60);
      if (p90 !== newPatients90Days) setNewPatients90Days(p90);
      if (p150 !== newPatients150Days) setNewPatients150Days(p150);
      if (paft !== newPatientsAfter) setNewPatientsAfter(paft);

      updateProjectedPatients(p30, p60, p90, p150, paft);
      setEnableEdit(false);
    }

    if (Array.isArray(data.Calls)) {
      let total = 0;
      let totalp = 0;
      let missed = 0;
      for (const call of data.Calls) {
        total++;
        totalp += call.How_Many_Appointments_Set__c;

        if (call.Call_Type__c === "Missed") missed++;
      }

      if (enableEdit) {
        if (total !== totalCalls30Days) setTotalCalls30Days(total);
        if (totalp !== totalNewPatients30Days)
          setTotalNewPatients30Days(totalp);
        if (missed !== totalMissedCalls30Days)
          setTotalMissedCalls30Days(missed);
      }

      currentMissedCallPercent =
        (uniqueCallers > 0 ? missedCalls / uniqueCallers : 0) * 100;
      currentMissedCallPercent = currentMissedCallPercent.toFixed(2);

      missedCallData.datasets[0].data.push(currentMissedCallPercent);
      missedCallData.datasets[0].data.push(30);
      if (+currentMissedCallPercent <= 40) {
        missedCallData.datasets[0].backgroundColor[0] = yellowColor;
        missedCallData.datasets[0].borderColor[0] = yellowBorderColor;
      }
      if (+currentMissedCallPercent <= 30) {
        missedCallData.datasets[0].backgroundColor[0] = greenColor;
        missedCallData.datasets[0].borderColor[0] = greenBorderColor;
      }

      closingRatioData.datasets[0].data.push(closeRatio);
      closingRatioData.datasets[0].data.push(55);
      if (+closeRatio <= 55 && +closeRatio >= 50) {
        closingRatioData.datasets[0].backgroundColor[0] = yellowColor;
        closingRatioData.datasets[0].borderColor[0] = yellowBorderColor;
      }
      if (+closeRatio > 55) {
        closingRatioData.datasets[0].backgroundColor[0] = greenColor;
        closingRatioData.datasets[0].borderColor[0] = greenBorderColor;
      }

      currentRevenue =
        (newPatients30Days +
          newPatients60Days +
          newPatients90Days +
          newPatients150Days +
          newPatientsAfter) *
        avgPatientValue;
      currentROI =
        (currentRevenue /
          (data.Order.Adjusted_Unit_Price__c * data.Order.Quantity__c)) *
        100;
      const revenueAtMaturity = projectedPatients * avgPatientValue;
      const roiAtMaturity =
        (revenueAtMaturity /
          (data.Order.Quantity__c * data.Order.Adjusted_Unit_Price__c)) *
        100;

      roiData.datasets[0].data.push(currentROI.toFixed(2));
      roiData.datasets[0].data.push(roiAtMaturity.toFixed(0));

      revenueData.datasets[0].data.push(currentRevenue.toFixed(2));
      revenueData.datasets[0].data.push(revenueAtMaturity.toFixed(0));
    }
  }

  const orderColumns = [
    {
      name: "Order",
      selector: (row) => row["Order_Label__c"],
      sortable: true,
    },
    {
      name: "In Home",
      selector: (row) => row["EffectiveDate"],
    },
    {
      name: "Status",
      selector: (row) => row["Status"],
      sortable: true,
    },
    {
      name: "Unit Price",
      selector: (row) => row["Adjusted_Unit_Price__c"],
    },
    {
      name: "Quantity",
      selector: (row) => row["Quantity__c"],
    },
    {
      name: "Total Amount",
      selector: (row) => row["Total_Amount__c"],
    },
  ];

  const paramOrderLabel = getUrlParameter("ol");
  if (paramOrderLabel && paramOrderLabel !== orderLabel) {
    setOrderLabel(paramOrderLabel);
    context.OrderLabel = paramOrderLabel;
    setLoading(true);
    setEnableEdit(true);
    getAPE(context)
      .then(function (result) {
        setData(result);
      })
      .finally(function () {
        setLoading(false);
      });
  }

  return (
    <>
      <Sidebar />
      <div className="content dashboard">
        {loading && !correctData && <Loader />}
        {!loading && !correctData && (
          <>
            <Row style={{ minHeight: "auto" }}>
              <Col>
                <Form.Control
                  type="text"
                  placeholder="Order Label"
                  value={orderLabel}
                  onChange={(e) => {
                    setOrderLabel(e.target.value);
                    context.OrderLabel = e.target.value;
                  }}
                />
                <Button
                  className="margin-left"
                  variant="primary"
                  style={{ marginTop: "-4px" }}
                  onClick={(e) => {
                    setLoading(true);
                    setEnableEdit(true);
                    getAPE(context)
                      .then(function (result) {
                        setData(result);
                      })
                      .finally(function () {
                        setLoading(false);
                      });
                  }}
                >
                  {!loading ? "Load Data" : "Loading..."}
                </Button>
              </Col>
            </Row>
          </>
        )}
        {correctData && (
          <Row style={{ minHeight: "auto" }}>
            <Col>
              <div className="chart-container chart-container-75-25">
                <div className="chart-col-75 full-cols">
                  <table className="form-table">
                    <thead>
                      <tr>
                        <th>Order</th>
                        <th>Account</th>
                        <th>QTY</th>
                        <th>Price</th>
                        <th>Date of 1st Call</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <a
                            href={`https://gargle.lightning.force.com/${data.Order.Id}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {data.Order.Order_Label__c}
                          </a>
                        </td>
                        <td>
                          <a
                            href={`https://gargle.lightning.force.com/${data.Account.Id}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {data.Account.Name}
                          </a>
                        </td>
                        <td>{data.Order.Quantity__c}</td>
                        <td>{data.Order.Adjusted_Unit_Price__c}</td>
                        <td>
                          {data.Order.Date_of_1st_Call__c}
                          <br />(
                          {dayDiff(
                            getDate(),
                            getDate(data.Order.Date_of_1st_Call__c)
                          )}{" "}
                          days)
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="form-table full-cols">
                    <thead>
                      <tr>
                        <th>Unique Callers</th>
                        <th>Close Ratio</th>
                        <th>Missed Calls</th>
                        <th>
                          First Year Avg <br />
                          Patient Value
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <input
                            value={uniqueCallers}
                            type="number"
                            onChange={(e) => setUniqueCallers(+e.target.value)}
                          />
                        </td>
                        <td>
                          <input
                            value={closeRatio}
                            type="number"
                            onChange={(e) => setCloseRatio(+e.target.value)}
                          />
                        </td>
                        <td>
                          <input
                            value={missedCalls}
                            type="number"
                            onChange={(e) => setMissedCalls(+e.target.value)}
                          />
                        </td>
                        <td>
                          <input
                            value={avgPatientValue}
                            type="number"
                            onChange={(e) =>
                              setAvgPatientValue(+e.target.value)
                            }
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="form-table full-cols">
                    <thead>
                      <tr>
                        <th>Total Calls (30 days)</th>
                        <th>New Patients (30 days)</th>
                        <th>Missed Calls (30 days)</th>
                        <th>
                          Projected Patients
                          <br />
                          at Maturity
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <input
                            value={totalCalls30Days}
                            type="number"
                            onChange={(e) =>
                              setTotalCalls30Days(+e.target.value)
                            }
                          />
                        </td>
                        <td>
                          <input
                            value={totalNewPatients30Days}
                            type="number"
                            onChange={(e) =>
                              setTotalNewPatients30Days(+e.target.value)
                            }
                          />
                        </td>
                        <td>
                          <input
                            value={totalMissedCalls30Days}
                            type="number"
                            onChange={(e) =>
                              setTotalMissedCalls30Days(+e.target.value)
                            }
                          />
                        </td>
                        <td>
                          <input
                            value={projectedPatients}
                            type="number"
                            onChange={(e) =>
                              setProjectedPatients(+e.target.value)
                            }
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="chart-col-25 full-cols">
                  <span className="title-like-table">
                    New Patients Current Campaign
                  </span>
                  <table className="form-table">
                    <thead>
                      <tr>
                        <th>30 Days</th>
                        <th>60 Days</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <input
                            value={newPatients30Days}
                            type="number"
                            onChange={(e) => {
                              setNewPatients30Days(+e.target.value);
                              updateProjectedPatients(
                                +e.target.value,
                                newPatients60Days,
                                newPatients90Days,
                                newPatients150Days,
                                newPatientsAfter
                              );
                            }}
                          />
                        </td>
                        <td>
                          <input
                            value={newPatients60Days}
                            type="number"
                            onChange={(e) => {
                              setNewPatients60Days(+e.target.value);
                              updateProjectedPatients(
                                newPatients30Days,
                                +e.target.value,
                                newPatients90Days,
                                newPatients150Days,
                                newPatientsAfter
                              );
                            }}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="form-table">
                    <thead>
                      <tr>
                        <th>90 Days</th>
                        <th>150 Days</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <input
                            value={newPatients90Days}
                            type="number"
                            onChange={(e) => {
                              setNewPatients90Days(+e.target.value);
                              updateProjectedPatients(
                                newPatients30Days,
                                newPatients60Days,
                                +e.target.value,
                                newPatients150Days,
                                newPatientsAfter
                              );
                            }}
                          />
                        </td>
                        <td>
                          <input
                            value={newPatients150Days}
                            type="number"
                            onChange={(e) => {
                              setNewPatients150Days(+e.target.value);
                              updateProjectedPatients(
                                newPatients30Days,
                                newPatients60Days,
                                newPatients90Days,
                                +e.target.value,
                                newPatientsAfter
                              );
                            }}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="form-table">
                    <thead>
                      <tr>
                        <th>After 150 Days</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <td>{newPatientsAfter}</td>
                        </td>
                        <td>
                          {newPatients30Days +
                            newPatients60Days +
                            newPatients90Days +
                            newPatients150Days +
                            newPatientsAfter}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div
                style={{
                  padding: "10px",
                  border: "1px solid #cbd0d5",
                  width: "100%",
                  display: "inline-block",
                  marginTop: "20px",
                }}
              >
                <div style={{ width: "100%" }}>
                  <Button
                    style={{ float: "right" }}
                    variant="light"
                    onClick={(e) => {
                      setLoading(true);
                      setEnableEdit(true);
                      getAPE(context)
                        .then(function (result) {
                          setData(result);
                        })
                        .finally(function () {
                          setLoading(false);
                        });
                    }}
                  >
                    {!loading ? "Refresh All Data" : "Refreshing..."}
                    <FontAwesomeIcon
                      icon={faSync}
                      style={{ marginLeft: "10px" }}
                    />
                  </Button>
                  <Button
                    variant="light"
                    style={{ float: "right" }}
                    onClick={(e) => setShowLastThreeOrders((prev) => !prev)}
                  >
                    {showLastThreeOrders ? "Hide" : "Show Last 3 Orders"}
                  </Button>
                </div>
                {showLastThreeOrders && (
                  <DataTable
                    highlightOnHover
                    columns={orderColumns}
                    data={data.Orders ? data.Orders : []}
                    onRowClicked={(row) =>
                      openURLInNewTab(
                        "https://gargle.lightning.force.com/" + row.Id
                      )
                    }
                  />
                )}
              </div>
              <div className="progress-report">
                <h4>
                  {data.Account.Name} -{" "}
                  {dateFormat(getDate(data.Order.EffectiveDate), "mm/dd/yyyy")}{" "}
                  Campaign -{" "}
                  {dayDiff(getDate(), getDate(data.Order.Date_of_1st_Call__c))}{" "}
                  days in-home
                </h4>
                <br />
                <table>
                  <tbody>
                    <tr>
                      <td className="border">
                        <b>CURRENT Campaign Tracked Results</b>
                        <br />
                        <p>
                          {newPatients30Days +
                            newPatients60Days +
                            newPatients90Days +
                            newPatients150Days +
                            newPatientsAfter}{" "}
                          New Patients *
                        </p>
                        <p>{uniqueCallers} New Callers **</p>
                        <p>{missedCalls} Missed Calls</p>
                      </td>
                      <td className="border">
                        <b>ALL OTHER Campaigns Results Last 30 Days </b>
                        <br />
                        <p>{totalNewPatients30Days} New Patients *</p>
                        <p>{totalCalls30Days} Callers **</p>
                        <p>{totalMissedCalls30Days} Missed Calls</p>
                      </td>
                      <td className="border nobottom">
                        <h4 className="blue" style={{ textAlign: "center" }}>
                          Staff Performance{" "}
                        </h4>
                        <p style={{ textAlign: "center", fontWeight: "bold" }}>
                          Current Campaign{" "}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td
                        colSpan="2"
                        className="col-big border tiny-chartContainer"
                      >
                        <div
                          style={{
                            marginTop: "-20px",
                            marginBottom: "40px",
                            fontSize: "12px",
                          }}
                        >
                          <p style={{ color: "gray", textAlign: "left" }}>
                            * Total New Patients from ALL Sources{" "}
                          </p>
                          <p style={{ color: "gray", textAlign: "left" }}>
                            **Calls that came through the tracking number on the
                            postcard
                          </p>
                        </div>
                        <div style={{ height: "120px", width: "550px" }}>
                          <DMStackedChart
                            subtitle="Return On Investment"
                            data={roiData}
                            horizontal={true}
                            percent={true}
                            darkFont={true}
                          />
                        </div>
                        <div
                          style={{
                            marginTop: "25px",
                            marginBottom: "15px",
                            fontSize: "15px",
                          }}
                        >
                          <p style={{ color: "gray", textAlign: "center" }}>
                            Baseline Success = 200% ROI • Goal = 400% ROI+
                          </p>
                        </div>
                        <div style={{ height: "120px", width: "550px" }}>
                          <DMStackedChart
                            subtitle="Revenue"
                            data={revenueData}
                            horizontal={true}
                            currency={true}
                            darkFont={true}
                          />
                        </div>
                        <div
                          style={{
                            fontSize: "15px",
                          }}
                        >
                          <p
                            style={{
                              marginTop: "25px",
                              color: "gray",
                              textAlign: "center",
                            }}
                          >
                            {data.NextOrder ? (
                              <>
                                Your next postcard mailing is scheduled to hit
                                homes{" "}
                                <b>
                                  {dateFormat(
                                    getDate(data.NextOrder.EffectiveDate),
                                    "mmmm dS, yyyy"
                                  )}
                                </b>
                              </>
                            ) : (
                              ""
                            )}
                          </p>
                        </div>
                      </td>
                      <td className="col-big border smaller-chartContainer notop">
                        {missedCalls > 0 && (
                          <div style={{ height: "200px", width: "350px" }}>
                            <DMStackedChart
                              subtitle="Missed Calls %"
                              data={missedCallData}
                              percent={true}
                              darkFont={true}
                            />
                          </div>
                        )}
                        {missedCalls === 0 && (
                          <div
                            style={{
                              height: "200px",
                              width: "100%",
                              padding: "20px",
                              marginTop: "-40px",
                            }}
                            className="border"
                          >
                            <img
                              alt="No Missed Calls"
                              src={noMissed}
                              style={{
                                width: "100%",
                                margin: "0 auto",
                                display: "block",
                              }}
                            />
                          </div>
                        )}

                        <br />
                        {closeRatio > 0 && (
                          <div
                            style={{
                              height: "200px",
                              width: "350px",
                              marginBottom: "20px",
                            }}
                          >
                            <DMStackedChart
                              subtitle="Closing Ratio %"
                              data={closingRatioData}
                              percent={true}
                              darkFont={true}
                            />
                          </div>
                        )}
                        {closeRatio === 0 && (
                          <div
                            style={{
                              height: "200px",
                              width: "100%",
                              padding: "20px",
                            }}
                            className="border"
                          >
                            <img
                              alt="No Close Ratio"
                              src={noClosed}
                              style={{
                                width: "100%",
                                margin: "0 auto",
                                display: "block",
                              }}
                            />
                          </div>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Col>
          </Row>
        )}
      </div>
    </>
  );
};

export default APE;
