import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../App";

import Sidebar from "../Sidebar";
import { getCSD } from "../DMData";
import {
  blueBorderColor,
  blueColor,
  DMPieChart,
  DMStackedChart,
  greenColor,
  orangeColor,
  redColor,
  yellowColor,
} from "../DMCharts";
import Loader from "../Loader";
import ErrorMessage from "../ErrorMessage";

import {
  _BOBbyStatus,
  _bookHygieneNoOrders,
  _bookHygieneNoPayment,
  _designStages,
  _groupTasks,
  _mapStages,
  _noStart,
  _progressReport,
  _proofreading,
  _rrTasks,
  _shippingNotes,
} from "../CSInit";

import { getStorageValue, openURLInNewTab } from "../Utils";
import Filter from "../Filter";
import OpenTasksTable from "../OpenTasksTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";

const CSD = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(false);

  const openTasks = [];
  const overdueTasks = [];
  const userOptionsOwners = [];

  const bookHygieneNoOrders = _bookHygieneNoOrders();
  const bookHygieneNoPaymentPC = _bookHygieneNoPayment();
  const bookHygieneNoPaymentNM = _bookHygieneNoPayment();
  const proofreading = _proofreading();
  const rrTasks = _rrTasks();
  const designStages = _designStages();
  const mapStages = _mapStages();
  const noStart = _noStart();
  const shippingNotes = _shippingNotes();
  const progressReport = _progressReport();
  const bob = _BOBbyStatus();
  let totalBOB = 0;

  const darkFont = true;

  const context = useContext(UserContext);

  const refresh = (load) => {
    if (context.accessToken !== null && load && !context.isLoading) {
      context.isLoading = true;
      getCSD(context).then(function (result) {
        refreshData(result);
      });
    }
  };

  const refreshData = (result) => {
    if (result === null) setError(true);
    else {
      setData(result);
      setError(false);
    }
  };

  useEffect(() => {
    refresh(!data);
  }); // Initial page load

  if (context.accessToken === null) return <></>;

  if (getStorageValue("dpm_analytics_owner_id"))
    context.OwnerId = getStorageValue("dpm_analytics_owner_id");

  if (data) {
    bookHygieneNoOrders.datasets[2].data.push(data.NoOrders.Red);
    bookHygieneNoOrders.datasets[1].data.push(data.NoOrders.Yellow);
    bookHygieneNoOrders.datasets[0].data.push(data.NoOrders.Green);

    bookHygieneNoPaymentPC.datasets[2].data.push(data.NoPaymentPC.Red);
    bookHygieneNoPaymentPC.datasets[1].data.push(data.NoPaymentPC.Yellow);
    bookHygieneNoPaymentPC.datasets[0].data.push(data.NoPaymentPC.Green);

    bookHygieneNoPaymentNM.datasets[2].data.push(data.NoPaymentNM.Red);
    bookHygieneNoPaymentNM.datasets[1].data.push(data.NoPaymentNM.Yellow);
    bookHygieneNoPaymentNM.datasets[0].data.push(data.NoPaymentNM.Green);

    proofreading.datasets[0].data.push(data.Proofreading.Red);
    proofreading.datasets[0].data.push(data.Proofreading.Yellow);
    proofreading.datasets[0].data.push(data.Proofreading.Green);
    proofreading.labels[0] =
      data.Proofreading.Red +
      data.Proofreading.Yellow +
      data.Proofreading.Green;

    rrTasks.datasets[2].data.push(data.RR.Red);
    rrTasks.datasets[1].data.push(data.RR.Yellow);
    rrTasks.datasets[0].data.push(data.RR.Green);
    rrTasks.labels[0] =
      data.RR.Red + data.RR.Yellow + data.RR.Green + data.RR.Gray;

    noStart.datasets[2].data.push(data.NoStart.Red);
    noStart.datasets[1].data.push(data.NoStart.Yellow);
    noStart.datasets[0].data.push(data.NoStart.Green);
    noStart.datasets[2].backgroundColor = orangeColor;
    noStart.datasets[2].borderColor = orangeColor;
    noStart.labels[0] =
      data.NoStart.Red + data.NoStart.Yellow + data.NoStart.Green;

    shippingNotes.datasets[2].data.push(data.ShippingNotes.Red);
    shippingNotes.datasets[1].data.push(data.ShippingNotes.Yellow);
    shippingNotes.datasets[0].data.push(data.ShippingNotes.Green);
    shippingNotes.datasets[2].backgroundColor = orangeColor;
    shippingNotes.datasets[2].borderColor = orangeColor;
    shippingNotes.labels[0] =
      data.ShippingNotes.Red +
      data.ShippingNotes.Yellow +
      data.ShippingNotes.Green;

    progressReport.datasets[2].data.push(data.ProgressReport.Red);
    progressReport.datasets[1].data.push(data.ProgressReport.Yellow);
    progressReport.datasets[0].data.push(data.ProgressReport.Green);
    progressReport.labels[0] =
      data.ProgressReport.Red +
      data.ProgressReport.Yellow +
      data.ProgressReport.Green;

    const today = new Date();
    if (today.getDay() === 1 || today.getDay() === 2) {
      progressReport.datasets[1].backgroundColor = blueColor;
      progressReport.datasets[1].borderColor = blueBorderColor;
    }

    const designMapStagesLabels = [
      ...new Set([
        ...Object.keys(data.DesignStagesRed),
        ...Object.keys(data.DesignStagesYellow),
        ...Object.keys(data.DesignStagesGreen),
        ...Object.keys(data.MapStagesRed),
        ...Object.keys(data.MapStagesYellow),
        ...Object.keys(data.MapStagesGreen),
      ]),
    ].reverse();
    designStages.labels = designMapStagesLabels;
    mapStages.labels = designMapStagesLabels;

    designStages.datasets[2].backgroundColor = orangeColor;
    designStages.datasets[2].borderColor = orangeColor;
    for (const k of designMapStagesLabels) {
      designStages.datasets[2].data.push(data.DesignStagesRed[k]);
      designStages.datasets[1].data.push(data.DesignStagesYellow[k]);
      designStages.datasets[0].data.push(data.DesignStagesGreen[k]);
    }
    mapStages.datasets[2].backgroundColor = orangeColor;
    mapStages.datasets[2].borderColor = orangeColor;
    for (const k of designMapStagesLabels) {
      mapStages.datasets[2].data.push(data.MapStagesRed[k]);
      mapStages.datasets[1].data.push(data.MapStagesYellow[k]);
      mapStages.datasets[0].data.push(data.MapStagesGreen[k]);
    }

    /*
      "DM - NEW",
      "Active - Initial",
      "Active - Renewed",
      "Active - Additional",
      "Active - Corporate",
      "Active - Determine Relationship",
    */
    bob.datasets[0].data.push(data.BOB["DM - NEW"]);
    bob.datasets[0].data.push(data.BOB["Active - Initial"]);
    bob.datasets[0].data.push(data.BOB["Active - Renewed"]);
    bob.datasets[0].data.push(data.BOB["Active - Additional"]);
    bob.datasets[0].data.push(data.BOB["Active - Corporate"]);
    bob.datasets[0].data.push(data.BOB["Active - Determine Relationship"]);
    for (const k in data.BOB) {
      totalBOB += +data.BOB[k];
    }

    _groupTasks(data.OpenTasks, openTasks, true);
    _groupTasks(data.OpenTasks, overdueTasks, false);

    userOptionsOwners.push({ value: "null", label: "All Users" });

    for (const u of data.Owners) {
      userOptionsOwners.push({ value: u.Id, label: u.Name });
    }

    if (context.OwnerId)
      context.Owner = userOptionsOwners.find(
        (item) => item.value === context.OwnerId
      );
  }

  return (
    <>
      {error && <ErrorMessage />}
      {!error && (
        <>
          <Sidebar />
          <div className="content dashboard">
            {!data && <Loader />}
            {data && (
              <>
                <Filter
                  context={context}
                  userOptionsOwners={userOptionsOwners}
                  filter={(e, setShowFilter) => {
                    e.target.disabled = true;
                    e.target.innerHTML = "Loading...";
                    window.location.reload();
                  }}
                />
                <div
                  className="chart-container chart-col-100"
                  style={{
                    gridTemplateColumns: "13% 13% 13% 13% 20% 28%",
                  }}
                >
                  <div className="cs-title">
                    {!context.OwnerId || context.Owner.value === "null"
                      ? "All Users"
                      : context.Owner.label}
                    <p className="cs-legend">
                      <span
                        className="square"
                        style={{ background: greenColor }}
                      ></span>
                      <span>Completed / On Time</span>
                      <span
                        className="square"
                        style={{ background: yellowColor }}
                      ></span>
                      <span>Needs Attention</span>
                      <span
                        className="square"
                        style={{ background: redColor }}
                      ></span>
                      <span>Urgent / Overdue</span>
                      <span
                        className="square"
                        style={{ background: orangeColor }}
                      ></span>
                      <span>Out of My Control</span>
                    </p>
                  </div>
                  <div
                    className="chart-col"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      openURLInNewTab(
                        "https://gargle.lightning.force.com/lightning/r/Report/00O2T000006WqEJUA0/view"
                      );
                    }}
                  >
                    <DMStackedChart
                      subtitle="RR Scheduling"
                      data={rrTasks}
                      darkFont={darkFont}
                      toolTip={
                        "All accounts needing an RR Call scheduled this week"
                      }
                    />
                  </div>
                  <div
                    className="chart-col"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      let u =
                        "https://gargle.lightning.force.com/lightning/r/Report/00O2E000009SvZfUAK/view";
                      openURLInNewTab(u);
                    }}
                  >
                    <DMStackedChart
                      subtitle="No Starts"
                      data={noStart}
                      darkFont={darkFont}
                      toolTip={
                        "All orders from last week that haven’t gotten calls yet."
                      }
                    />
                  </div>
                  <div
                    className="chart-col"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      openURLInNewTab(
                        "https://gargle.lightning.force.com/lightning/r/Report/00O2T000006WqDfUAK/view"
                      );
                    }}
                  >
                    <DMStackedChart
                      subtitle="Late Deliveries"
                      data={shippingNotes}
                      darkFont={darkFont}
                      toolTip={
                        "Any order delivered more than 1 business day past the expected delivery date to the SCF."
                      }
                    />
                  </div>
                  <div
                    className="chart-col"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      window.location.href =
                        "/report?action=getPROGRESS&report=PROGRESS&fields=OrderLabel_OrderDate&fieldnames=Order_In Home Week&returnto=/directors";
                    }}
                  >
                    <DMStackedChart
                      subtitle="Progress Report"
                      data={progressReport}
                      darkFont={darkFont}
                      toolTip={"All progress reports to send this week"}
                    />
                  </div>
                  <div
                    className="chart-col"
                    style={{ cursor: "pointer", padding: "10px" }}
                  >
                    <div className="chartTitle">
                      <h5>Coaching Videos</h5>
                    </div>
                    <div className="chartContainer">
                      <a
                        href="https://intranet.dentalmarketing.net/wiki/dental-kpi-videos-with-links/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon icon={faArrowCircleRight} /> View All
                        Links and Descriptions clicking here
                      </a>
                      <br />
                      <br />
                      <p
                        style={{
                          borderBottom: "1px solid gray",
                          width: "100%",
                          textTransform: "uppercase",
                          color: "gray",
                        }}
                      >
                        Quick access:
                      </p>
                      <a
                        href="https://www.youtube.com/watch?v=NR7YvIMotlg&list=PLyojr9o7UgPtKI0sJpLC5Ltjbit7GltiJ&index=2&t=0s"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon icon={faArrowCircleRight} />{" "}
                        Introducing the practice
                      </a>
                      <br />
                      <a
                        href="https://www.youtube.com/watch?v=SHlr_nickPY&list=PLyojr9o7UgPtKI0sJpLC5Ltjbit7GltiJ&index=3&t=0s"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon icon={faArrowCircleRight} />{" "}
                        Introducing yourself
                      </a>
                      <br />
                      <a
                        href="https://www.youtube.com/watch?v=W5yJdbCVQl0&list=PLyojr9o7UgPtKI0sJpLC5Ltjbit7GltiJ&index=4&t=0s"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon icon={faArrowCircleRight} /> Connecting
                        with your callers
                      </a>
                      <br />
                      <a
                        href="https://www.youtube.com/watch?v=FhZ9_dnoGq0&list=PLyojr9o7UgPtKI0sJpLC5Ltjbit7GltiJ&index=5&t=0s"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon icon={faArrowCircleRight} /> Converting
                        the most calls into New Patients
                      </a>
                    </div>
                  </div>
                  <div
                    className="chart-col"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      openURLInNewTab(
                        "https://gargle.lightning.force.com/lightning/r/Report/00O2T000004aJPSUA2/view"
                      );
                    }}
                  >
                    <DMPieChart
                      subtitle="BOB Report by Status"
                      data={bob}
                      darkFont={darkFont}
                      toolTip={`Shows all active accounts by account status.`}
                      isDoughnut={true}
                      height="280px"
                      total={totalBOB}
                    />
                  </div>
                </div>
                <div className="chart-container chart-container-33">
                  <div
                    className="chart-col chart-col-33"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      openURLInNewTab(
                        context.Owner === undefined ||
                          context.Owner.label.indexOf("None") !== -1 ||
                          context.Owner.label.indexOf("All Users") !== -1
                          ? "https://gargle.lightning.force.com/lightning/r/Report/00O2T000006WqEYUA0/view"
                          : context.Owner.label.indexOf("Sean") !== -1
                          ? "https://gargle.lightning.force.com/lightning/o/Order/list?filterName=00B2T0000083rEvUAI"
                          : context.Owner.label.indexOf("Alexis") !== -1
                          ? "https://gargle.lightning.force.com/lightning/o/Order/list?filterName=00B2T0000083sd8UAA"
                          : "https://gargle.lightning.force.com/lightning/o/Order/list?filterName=00B2T000009EmAJUA0"
                      );
                    }}
                  >
                    <DMPieChart
                      subtitle="Ready To Send - Design"
                      data={proofreading}
                      height="300px"
                      darkFont={darkFont}
                      toolTip={
                        "Design: All orders with a ‘ready to send’ design stage"
                      }
                    />
                  </div>
                  <div
                    className="chart-col chart-col-33"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      openURLInNewTab(
                        "https://gargle.lightning.force.com/lightning/r/Report/00O2E000009SvZgUAK/view"
                      );
                    }}
                  >
                    <DMStackedChart
                      subtitle="Design Stages"
                      data={designStages}
                      darkFont={darkFont}
                      toolTip={
                        "Shows all orders slated for the next 7 weeks, sorted by design stage"
                      }
                    />
                  </div>
                  <div
                    className="chart-col chart-col-33"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      openURLInNewTab(
                        "https://gargle.lightning.force.com/lightning/r/Report/00O2E000009SvZgUAK/view"
                      );
                    }}
                  >
                    <DMStackedChart
                      subtitle="Map Stages"
                      data={mapStages}
                      darkFont={darkFont}
                      toolTip={
                        "Shows all orders slated for the next 7 weeks, sorted by map stage"
                      }
                    />
                  </div>
                </div>

                <OpenTasksTable
                  openTasks={openTasks}
                  overdueTasks={overdueTasks}
                />
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default CSD;
