import axios from "axios";

const serverUrl = "https://webapi.startupp.cloud/access/WebAPI/";
const devServerUrl = "https://localhost:44302/access/WebAPI/";
const isDev = false;
const isSandbox = false;

const authorize = async (context) => {
  return new Promise((resolve, reject) => {
    const sParams = {
      requestKey: !isSandbox
        ? "4d3d57e1-1d9b-4f4b-9f64-16574f211c27"
        : "SANDBOX-3287285f-f874-4b22-8c1b-8bb2ec696852",
      action: "authorize",
    };

    try {
      if (!context.AUTH_CODE) {
        axios
          .post(isDev ? devServerUrl : serverUrl, sParams)
          .then((response) => {
            if (response) {
              context.AUTH_CODE = response.data.authorization;
              resolve(context.AUTH_CODE);
            }
          })
          .catch((error) => {
            context.AUTH_CODE = "";
            reject(context.AUTH_CODE);
          });
      } else {
        resolve(context.AUTH_CODE);
      }
    } catch (err) {
      context.AUTH_CODE = "";
      reject(context.AUTH_CODE);
    }
  });
};

export const query = async (obj, fields, filter, context) => {
  try {
    const AUTH_CODE = await authorize(context); // Requests an authorization code before making actual calls

    if (context.SF_TOKEN) {
      const renewToken = (new Date() - context.SF_TOKEN_TIME) / 3600000;
      console.log(renewToken);
      if (renewToken >= 2) {
        context.SF_TOKEN = undefined;
        context.SF_URL = undefined;
      }
    }

    const sParams = {
      requestKey: !isSandbox
        ? "4d3d57e1-1d9b-4f4b-9f64-16574f211c27"
        : "SANDBOX-3287285f-f874-4b22-8c1b-8bb2ec696852",
      requestCode: AUTH_CODE,
      action: "sf-search",
      token: context.SF_TOKEN,
      url: context.SF_URL,
      search: [
        {
          sobject: obj,
          fields: fields,
          filter: filter,
        },
      ],
    };

    const { data } = await axios.post(
      isDev ? devServerUrl : serverUrl,
      sParams
    );

    const response = data;
    if (response.token) {
      context.SF_TOKEN = response.token;
      context.SF_URL = response.url;
      context.SF_TOKEN_TIME = new Date();
    }
    return response[obj];
  } catch (err) {
    return null;
  }
};

export const rest = async (
  name,
  svcName,
  action,
  requestKeys,
  context,
  isRetry = false
) => {
  try {
    const AUTH_CODE = await authorize(context); // Requests an authorization code before making actual calls

    if (context.SF_TOKEN) {
      const renewToken = (new Date() - context.SF_TOKEN_TIME) / 3600000;
      if (renewToken >= 2) {
        context.SF_TOKEN = undefined;
        context.SF_URL = undefined;
      }
    }

    const b = {
      Request: action,
      ...requestKeys,
    };

    const sParams = {
      requestKey: !isSandbox
        ? "4d3d57e1-1d9b-4f4b-9f64-16574f211c27"
        : "SANDBOX-3287285f-f874-4b22-8c1b-8bb2ec696852",
      requestCode: AUTH_CODE,
      action: "sf-rest",
      token: context.SF_TOKEN,
      url: context.SF_URL,
      rest: [
        {
          actionName: name,
          serviceName: svcName,
          body: JSON.stringify(b),
        },
      ],
    };

    const { data } = await axios.post(
      isDev ? devServerUrl : serverUrl,
      sParams
    );
    const response = data;
    if (response.token) {
      context.SF_TOKEN = response.token;
      context.SF_URL = response.url;
      context.SF_TOKEN_TIME = new Date();
    }
    /////// TRY TO PARSE RESPONSE, IF DOESN'T WORK RETRY CALL AT LEAST ONCE :::::
    try {
      const r = JSON.parse(response[name]);
    } catch (err) {
      if (!isRetry)
        return await rest(name, svcName, action, requestKeys, context, true);
      else return null;
    }

    return response[name];
  } catch (err) {
    console.log(err);
    return null;
  }
};
